/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddClaimViewModel } from '../models/add-claim-view-model';
import { BooleanDataWrapperVm } from '../models/boolean-data-wrapper-vm';
import { ChangeAnyPasswordViewModel } from '../models/change-any-password-view-model';
import { ChangePasswordViewModel } from '../models/change-password-view-model';
import { Roles } from '../models/roles';
import { TokenApiModel } from '../models/token-api-model';
import { TokenBagViewModel } from '../models/token-bag-view-model';
import { UserViewModel } from '../models/user-view-model';
import { UserViewModelPaginatedResponse } from '../models/user-view-model-paginated-response';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiAccountsIdClaimsTypeDelete
   */
  static readonly V10ApiAccountsIdClaimsTypeDeletePath = '/v1.0/Api/Accounts/{id}/Claims/{type}';

  /**
   * Remove a claim for a user, System Admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdClaimsTypeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdClaimsTypeDelete$Response(params: {
    id: string;
    type: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdClaimsTypeDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Remove a claim for a user, System Admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdClaimsTypeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdClaimsTypeDelete(params: {
    id: string;
    type: string;
  }): Observable<void> {

    return this.v10ApiAccountsIdClaimsTypeDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdDeactivateDelete
   */
  static readonly V10ApiAccountsIdDeactivateDeletePath = '/v1.0/Api/Accounts/{id}/Deactivate';

  /**
   * deactivate a user, System Admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdDeactivateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdDeactivateDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdDeactivateDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * deactivate a user, System Admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdDeactivateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdDeactivateDelete(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiAccountsIdDeactivateDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIsAccessTokenValidGet
   */
  static readonly V10ApiAccountsIsAccessTokenValidGetPath = '/v1.0/Api/Accounts/IsAccessTokenValid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIsAccessTokenValidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIsAccessTokenValidGet$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIsAccessTokenValidGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIsAccessTokenValidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIsAccessTokenValidGet(params?: {
  }): Observable<void> {

    return this.v10ApiAccountsIsAccessTokenValidGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdCheckFirstLoginGet
   */
  static readonly V10ApiAccountsIdCheckFirstLoginGetPath = '/v1.0/Api/Accounts/{id}/CheckFirstLogin';

  /**
   * Get if it the first login , Anonymous usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdCheckFirstLoginGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdCheckFirstLoginGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<BooleanDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdCheckFirstLoginGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDataWrapperVm>;
      })
    );
  }

  /**
   * Get if it the first login , Anonymous usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdCheckFirstLoginGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdCheckFirstLoginGet$Plain(params: {
    id: string;
  }): Observable<BooleanDataWrapperVm> {

    return this.v10ApiAccountsIdCheckFirstLoginGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDataWrapperVm>) => r.body as BooleanDataWrapperVm)
    );
  }

  /**
   * Get if it the first login , Anonymous usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdCheckFirstLoginGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdCheckFirstLoginGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<BooleanDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdCheckFirstLoginGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDataWrapperVm>;
      })
    );
  }

  /**
   * Get if it the first login , Anonymous usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdCheckFirstLoginGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdCheckFirstLoginGet$Json(params: {
    id: string;
  }): Observable<BooleanDataWrapperVm> {

    return this.v10ApiAccountsIdCheckFirstLoginGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDataWrapperVm>) => r.body as BooleanDataWrapperVm)
    );
  }

  /**
   * Path part for operation v10ApiAccountsLoginFirstTimePost
   */
  static readonly V10ApiAccountsLoginFirstTimePostPath = '/v1.0/Api/Accounts/LoginFirstTime';

  /**
   * Login First Time , Anonymous usage .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsLoginFirstTimePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginFirstTimePost$Plain$Response(params?: {
    body?: { 'NationalId': string, 'Password': string, 'ConfirmPassword': string, 'PhoneNumber': string, 'NameAr': string, 'Email'?: string, 'JobDesc'?: string, 'PhotoUrl'?: string }
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsLoginFirstTimePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * Login First Time , Anonymous usage .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsLoginFirstTimePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginFirstTimePost$Plain(params?: {
    body?: { 'NationalId': string, 'Password': string, 'ConfirmPassword': string, 'PhoneNumber': string, 'NameAr': string, 'Email'?: string, 'JobDesc'?: string, 'PhotoUrl'?: string }
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsLoginFirstTimePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * Login First Time , Anonymous usage .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsLoginFirstTimePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginFirstTimePost$Json$Response(params?: {
    body?: { 'NationalId': string, 'Password': string, 'ConfirmPassword': string, 'PhoneNumber': string, 'NameAr': string, 'Email'?: string, 'JobDesc'?: string, 'PhotoUrl'?: string }
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsLoginFirstTimePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * Login First Time , Anonymous usage .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsLoginFirstTimePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginFirstTimePost$Json(params?: {
    body?: { 'NationalId': string, 'Password': string, 'ConfirmPassword': string, 'PhoneNumber': string, 'NameAr': string, 'Email'?: string, 'JobDesc'?: string, 'PhotoUrl'?: string }
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsLoginFirstTimePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdReturnFirstLoginPut
   */
  static readonly V10ApiAccountsIdReturnFirstLoginPutPath = '/v1.0/Api/Accounts/{id}/ReturnFirstLogin';

  /**
   * Return First Login , Admin usage .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdReturnFirstLoginPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdReturnFirstLoginPut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdReturnFirstLoginPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Return First Login , Admin usage .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdReturnFirstLoginPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdReturnFirstLoginPut(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiAccountsIdReturnFirstLoginPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsLoginPost
   */
  static readonly V10ApiAccountsLoginPostPath = '/v1.0/Api/Accounts/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsLoginPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginPost$Plain$Response(params?: {
    body?: { 'NationalId': string, 'Password': string }
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsLoginPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginPost$Plain(params?: {
    body?: { 'NationalId': string, 'Password': string }
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsLoginPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsLoginPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginPost$Json$Response(params?: {
    body?: { 'NationalId': string, 'Password': string }
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsLoginPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsLoginPost$Json(params?: {
    body?: { 'NationalId': string, 'Password': string }
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsLoginPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsGet
   */
  static readonly V10ApiAccountsGetPath = '/v1.0/Api/Accounts';

  /**
   * Get The Users ,, .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsGet$Plain$Response(params?: {
    textFilter?: string;
    role?: Roles;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<UserViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('role', params.role, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get The Users ,, .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsGet$Plain(params?: {
    textFilter?: string;
    role?: Roles;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<UserViewModelPaginatedResponse> {

    return this.v10ApiAccountsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModelPaginatedResponse>) => r.body as UserViewModelPaginatedResponse)
    );
  }

  /**
   * Get The Users ,, .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsGet$Json$Response(params?: {
    textFilter?: string;
    role?: Roles;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<UserViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('role', params.role, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get The Users ,, .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsGet$Json(params?: {
    textFilter?: string;
    role?: Roles;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<UserViewModelPaginatedResponse> {

    return this.v10ApiAccountsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModelPaginatedResponse>) => r.body as UserViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiAccountsPost
   */
  static readonly V10ApiAccountsPostPath = '/v1.0/Api/Accounts';

  /**
   * Register a new user , Roles =  "President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin.
   *
   * President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsPost$Plain$Response(params?: {
    body?: { 'NationalId': string, 'NameAr'?: string, 'Email'?: string, 'Role': Roles, 'JobDesc'?: string, 'PhotoUrl'?: string, 'PhoneNumber'?: string, 'IsGlobalReviewer': boolean }
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Register a new user , Roles =  "President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin.
   *
   * President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsPost$Plain(params?: {
    body?: { 'NationalId': string, 'NameAr'?: string, 'Email'?: string, 'Role': Roles, 'JobDesc'?: string, 'PhotoUrl'?: string, 'PhoneNumber'?: string, 'IsGlobalReviewer': boolean }
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Register a new user , Roles =  "President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin.
   *
   * President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsPost$Json$Response(params?: {
    body?: { 'NationalId': string, 'NameAr'?: string, 'Email'?: string, 'Role': Roles, 'JobDesc'?: string, 'PhotoUrl'?: string, 'PhoneNumber'?: string, 'IsGlobalReviewer': boolean }
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Register a new user , Roles =  "President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin.
   *
   * President|ExecutiveManager|EngManagementDirector|SystemAdmin|NormalUser|Developer" ,  Require a system admin
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsPost$Json(params?: {
    body?: { 'NationalId': string, 'NameAr'?: string, 'Email'?: string, 'Role': Roles, 'JobDesc'?: string, 'PhotoUrl'?: string, 'PhoneNumber'?: string, 'IsGlobalReviewer': boolean }
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdGet
   */
  static readonly V10ApiAccountsIdGetPath = '/v1.0/Api/Accounts/{id}';

  /**
   * Get The User by Id .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Get The User by Id .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdGet$Plain(params: {
    id: string;
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Get The User by Id .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Get The User by Id .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdGet$Json(params: {
    id: string;
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdPut
   */
  static readonly V10ApiAccountsIdPutPath = '/v1.0/Api/Accounts/{id}';

  /**
   * Update a user, System Admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsIdPut$Plain$Response(params: {
    id: string;
    body?: { 'NameAr': string, 'Email'?: string, 'PhoneNumber'?: string, 'PhotoUrl'?: string, 'JobDesc'?: string, 'IsGlobalReviewer'?: boolean, 'NewRole'?: Roles }
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Update a user, System Admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsIdPut$Plain(params: {
    id: string;
    body?: { 'NameAr': string, 'Email'?: string, 'PhoneNumber'?: string, 'PhotoUrl'?: string, 'JobDesc'?: string, 'IsGlobalReviewer'?: boolean, 'NewRole'?: Roles }
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Update a user, System Admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsIdPut$Json$Response(params: {
    id: string;
    body?: { 'NameAr': string, 'Email'?: string, 'PhoneNumber'?: string, 'PhotoUrl'?: string, 'JobDesc'?: string, 'IsGlobalReviewer'?: boolean, 'NewRole'?: Roles }
  }): Observable<StrictHttpResponse<UserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserViewModel>;
      })
    );
  }

  /**
   * Update a user, System Admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiAccountsIdPut$Json(params: {
    id: string;
    body?: { 'NameAr': string, 'Email'?: string, 'PhoneNumber'?: string, 'PhotoUrl'?: string, 'JobDesc'?: string, 'IsGlobalReviewer'?: boolean, 'NewRole'?: Roles }
  }): Observable<UserViewModel> {

    return this.v10ApiAccountsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserViewModel>) => r.body as UserViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsRefreshTokenPut
   */
  static readonly V10ApiAccountsRefreshTokenPutPath = '/v1.0/Api/Accounts/RefreshToken';

  /**
   * Refresh a  token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsRefreshTokenPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsRefreshTokenPut$Plain$Response(params?: {
    body?: TokenApiModel
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsRefreshTokenPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * Refresh a  token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsRefreshTokenPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsRefreshTokenPut$Plain(params?: {
    body?: TokenApiModel
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsRefreshTokenPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * Refresh a  token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsRefreshTokenPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsRefreshTokenPut$Json$Response(params?: {
    body?: TokenApiModel
  }): Observable<StrictHttpResponse<TokenBagViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsRefreshTokenPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenBagViewModel>;
      })
    );
  }

  /**
   * Refresh a  token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsRefreshTokenPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsRefreshTokenPut$Json(params?: {
    body?: TokenApiModel
  }): Observable<TokenBagViewModel> {

    return this.v10ApiAccountsRefreshTokenPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenBagViewModel>) => r.body as TokenBagViewModel)
    );
  }

  /**
   * Path part for operation v10ApiAccountsRevokeTokenDelete
   */
  static readonly V10ApiAccountsRevokeTokenDeletePath = '/v1.0/Api/Accounts/RevokeToken';

  /**
   * Revoke  a  token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsRevokeTokenDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsRevokeTokenDelete$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsRevokeTokenDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Revoke  a  token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsRevokeTokenDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsRevokeTokenDelete(params?: {
  }): Observable<void> {

    return this.v10ApiAccountsRevokeTokenDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdChangeAnyPasswordPut
   */
  static readonly V10ApiAccountsIdChangeAnyPasswordPutPath = '/v1.0/Api/Accounts/{id}/ChangeAnyPassword';

  /**
   * Change the password for any user by Id. , System Admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdChangeAnyPasswordPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsIdChangeAnyPasswordPut$Response(params: {
    id: string;
    body?: ChangeAnyPasswordViewModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdChangeAnyPasswordPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change the password for any user by Id. , System Admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdChangeAnyPasswordPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsIdChangeAnyPasswordPut(params: {
    id: string;
    body?: ChangeAnyPasswordViewModel
  }): Observable<void> {

    return this.v10ApiAccountsIdChangeAnyPasswordPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsChangePasswordPut
   */
  static readonly V10ApiAccountsChangePasswordPutPath = '/v1.0/Api/Accounts/ChangePassword';

  /**
   * Change current authorized password , login Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsChangePasswordPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsChangePasswordPut$Response(params?: {
    body?: ChangePasswordViewModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsChangePasswordPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change current authorized password , login Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsChangePasswordPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsChangePasswordPut(params?: {
    body?: ChangePasswordViewModel
  }): Observable<void> {

    return this.v10ApiAccountsChangePasswordPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdAddClaimPut
   */
  static readonly V10ApiAccountsIdAddClaimPutPath = '/v1.0/Api/Accounts/{id}/AddClaim';

  /**
   * Add Claim, Require sys admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdAddClaimPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsIdAddClaimPut$Response(params: {
    id: string;
    body?: AddClaimViewModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdAddClaimPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add Claim, Require sys admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdAddClaimPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiAccountsIdAddClaimPut(params: {
    id: string;
    body?: AddClaimViewModel
  }): Observable<void> {

    return this.v10ApiAccountsIdAddClaimPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiAccountsIdActivatePut
   */
  static readonly V10ApiAccountsIdActivatePutPath = '/v1.0/Api/Accounts/{id}/Activate';

  /**
   * Activate user , Require system Admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiAccountsIdActivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdActivatePut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.V10ApiAccountsIdActivatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate user , Require system Admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiAccountsIdActivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiAccountsIdActivatePut(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiAccountsIdActivatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
