import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotMessage } from './extra-account.service';

@Injectable({
  providedIn: 'root'
})
export class ChatExtrasService {
  messagesSubject = new BehaviorSubject<NotMessage | null>(null);

  constructor() { }
}
