import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ConnectionCheckInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 0) {
          this.router.navigate(['/noInternet']);
        } else if (error.status === 401) {
          this.router.navigate(['/authProEye']);
        }
        return throwError(error);
      })
    );
  }
}

// "Http failure response for https://https://production-d42qaudzkq-ew.a.run.app/v1.0/Api/FCM/AssignToken: 0 Unknown Error"
