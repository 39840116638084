import { NoInternetComponent } from './no-internet/no-internet.component';
import { NoPageComponent } from './no-page/no-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'university',
    pathMatch: 'full',
  },
  {
    path: 'university',
    loadChildren: () =>
      import('src/app/public/public.module').then((p) => p.PublicModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('src/app/private/private.module').then((c) => c.PrivateModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'authProEye',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((a) => a.AuthModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'noInternet',
    component: NoInternetComponent,
  },
  {
    path: '**',
    component: NoPageComponent,
  },
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
