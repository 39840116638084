<div
  class="w-100 h-100"
  fxLayout="column"

  fxLayoutAlign="center center"
>
  <img
    src="./../../assets/images/logo-03-nocontent.png"
    style="border-radius: 10px; width: 35rem; margin-bottom: 3rem"
  />
  <p class="text-bold-6 text-primary font-custom text-shadow">
    لا يوجد اتصال بالانترنت
  </p>
  <button
    mat-raised-button
    style="
      background-color: #0a3556;
      color: #fff;
      width: 30rem;
      margin-top: 3rem;
    "
    class="btn-rounded"
    (click)="onBack()"
  >
    العودة
  </button>
</div>
