/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  ClaimViewModel,
  SelfOrOtherEnum,
  TokenBagViewModel,
  UserViewModel,
} from '../api/models';
import { AccountsService, FcmService } from '../api/services';
import { User } from '../auth/user.model';
import { ChatExtrasService } from './chat-extras.service';

export enum priority {
  Normal = 'normal',
}

export interface LoginFirstTimeViewModel {
  NationalId: string;
  Password: string;
  ConfirmPassword: string;
  PhoneNumber: string;
  NameAr: string;
  Email?: string;
  ImageUrl?: string;
  JobDesc?: string;
}

export interface LoginViewModel {
  NationalId: string;
  Password: string;
}

export interface NotMessage {
  projectId: string;
  chatRoomId?: string;
  messageId?: string;
  issuedDate?: string;
  issuerId?: string;
  content?: string;
  attachments?: [];
  selfOrOther?: SelfOrOtherEnum;
  title?: string;
  priority?: priority;
  from?: string;
}

@Injectable({ providedIn: 'root' })
export class ExtraAccountsService {
  user = new BehaviorSubject<TokenBagViewModel | null>(null);
  private timerHandle?: number;
  newMessage!: NotMessage;
  private userSub?: Subscription;
  constructor(
    private accountService: AccountsService,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private fcmService: FcmService,
    private chatExtraService: ChatExtrasService
  ) {

  }


  private localStorageKey = 'proEyeUserData';
  _initStream() {
    if (this.userSub) return;
    this.userSub = this.user.subscribe((bag) => {
      if (this.timerHandle) {
        clearTimeout(this.timerHandle);
      }
      if (!bag || !bag.expiration) {
        this.timerHandle = undefined;
      }
      else {
        const diff = new Date(bag!.expiration!).getTime() - new Date().getTime();
        this.timerHandle = setTimeout(this.initAuthLogic as TimerHandler, diff);
      }

      if (bag) {

        //fcm token
        this.requestPermission().subscribe((tok) => {
          if (tok) {
            console.log(tok);
            this.fcmService
              .v10ApiFcmAssignTokenPost({
                body: { token: tok! },
              })
              .subscribe((res) => { });
          }
        });

        this.listen().subscribe((message: any) => {
          const data = JSON.parse(message.data['*']);
          this.newMessage = {
            title: message.notification.title,
            projectId: data.projectId,
            attachments: data.attachments,
            chatRoomId: data.chatRoomId,
            content: data.content,
            issuedDate: new Date(data.issuedDate).toString(),
            issuerId: data.issuerId,
            messageId: data.messageId,
            selfOrOther: data.selfOrOther,
            priority: message.priority,
            from: message.from,
          };
          this.chatExtraService.messagesSubject.next(this.newMessage);
        });
      }

    });
  }


  isValid(bag: TokenBagViewModel): boolean {
    if (!bag.expiration) return false;
    return new Date(bag.expiration!) < new Date() ?? false;
  }

  registerTokenBag(bag: TokenBagViewModel) {
    if (bag) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(bag));
    }
    else {
      localStorage.removeItem(this.localStorageKey);
    }
    this.user.next(bag);
  }

  async requestNewTokenBag(bag: TokenBagViewModel): Promise<TokenBagViewModel> {
    if (!bag.accessToken || !bag.refreshToken) throw new Error("Bag should have access and refresh token");
    return await this.accountService.v10ApiAccountsRefreshTokenPut$Json({
      body: {
        accessToken: bag.accessToken,
        refreshToken: bag.refreshToken,
      }
    }).toPromise();
  }

  async extraValidation(bag: TokenBagViewModel): Promise<boolean> {
    return await this.accountService.v10ApiAccountsIsAccessTokenValidGet().toPromise().then(() => true).catch(() => false);
  }

  async initAuthLogic(): Promise<boolean | undefined> {
    this._initStream();
    const saved = localStorage.getItem(this.localStorageKey);
    if (!saved) {
      return undefined;
    }
    const bag = JSON.parse(saved) as TokenBagViewModel;
    if (!this.isValid(bag) || !await this.extraValidation(bag)) {
      if (!bag.refreshToken) {
        return false;
      }
      else {
        try {
          const token = await this.requestNewTokenBag(bag);
          this.registerTokenBag(token);
          return true;
        } catch (error) {
          localStorage.removeItem(this.localStorageKey);
          return false;
        }
      }
    }
    return true;
  }



  // logout
  logout() {
    clearTimeout(this.timerHandle);
    this.deleteToken();
    localStorage.removeItem(this.localStorageKey);
    this.user.next(null);
    //localStorage.removeItem('proEyeNormalUserData');
    this.router.navigate(['/authProEye']);
  }

  private deleteToken() {
    try {
      this.afMessaging.getToken
        .pipe(mergeMap((token) => this.afMessaging.deleteToken(token!)))
        .subscribe((token) => {
          console.log('Token deleted!');
        });
    } catch (error) {
      console.log('error deleteToken :>> ', error);
    }

  }

  // end of logout


  // auto redirect
  checkRoles(user: TokenBagViewModel) {
    if (user) {
      switch (user.user?.role) {
        case 'President':
          this.router.navigate(['/user/dashboard/projects-details']);
          break;

        case 'ExecutiveManager':
          this.router.navigate(['/user/dashboard/projects-requests-exe']);
          break;

        case 'EngManagementDirector':
          this.router.navigate(['/user/dashboard/projects-details']);
          break;

        case 'SystemAdmin':
          this.router.navigate(['/user/dashboard/users']);
          break;

        case 'NormalUser':
          this.router.navigate(['/user/projects']);
          break;

        case 'Developer':
          this.router.navigate(['/user/dashboard/users']);
          break;
      }
    }
  }
  // end of redirect



  // request fcm permission
  requestPermission() {
    return this.afMessaging.requestToken;
  }

  // listen
  listen() {
    return this.afMessaging.messages;
  }
}
