/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectExtractAbstractVmPaginatedResponse } from '../models/project-extract-abstract-vm-paginated-response';
import { ProjectExtractVm } from '../models/project-extract-vm';
import { RemainingExtractVmPaginatedResponse } from '../models/remaining-extract-vm-paginated-response';
import { ReqCreateExtractReqNewFilesWrapper } from '../models/req-create-extract-req-new-files-wrapper';
import { ReqCreateExtractReqOldNewFilesWrapper } from '../models/req-create-extract-req-old-new-files-wrapper';
import { ReqReviewExtractReqNewFilesWrapper } from '../models/req-review-extract-req-new-files-wrapper';
import { StringDataWrapperVm } from '../models/string-data-wrapper-vm';

@Injectable({
  providedIn: 'root',
})
export class ExtractsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsGet
   */
  static readonly V10ApiProjectsIdExtractsGetPath = '/v1.0/Api/Projects/{id}/Extracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsGet$Plain$Response(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
    isIncomingOnly?: boolean;
  }): Observable<StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterStr', params.filterStr, {});
      rb.query('isIncomingOnly', params.isIncomingOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsGet$Plain(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
    isIncomingOnly?: boolean;
  }): Observable<ProjectExtractAbstractVmPaginatedResponse> {

    return this.v10ApiProjectsIdExtractsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>) => r.body as ProjectExtractAbstractVmPaginatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsGet$Json$Response(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
    isIncomingOnly?: boolean;
  }): Observable<StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterStr', params.filterStr, {});
      rb.query('isIncomingOnly', params.isIncomingOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsGet$Json(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
    isIncomingOnly?: boolean;
  }): Observable<ProjectExtractAbstractVmPaginatedResponse> {

    return this.v10ApiProjectsIdExtractsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectExtractAbstractVmPaginatedResponse>) => r.body as ProjectExtractAbstractVmPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiProjectsRemainingExtractsGet
   */
  static readonly V10ApiProjectsRemainingExtractsGetPath = '/v1.0/Api/Projects/RemainingExtracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsRemainingExtractsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsRemainingExtractsGet$Plain$Response(params?: {
    IsSearchByMissing?: boolean;
    FilterGovAgencies?: Array<string>;
    IssuedAt?: boolean;
    AcquisitionDate?: boolean;
    IsSelfFunded?: boolean;
    Value?: boolean;
    WorkDoneDate?: boolean;
    WorkDoneValue?: boolean;
    InitialValue?: boolean;
    ValueAfterCommissions?: boolean;
    AccountingUnitReceivedAt?: boolean;
    BankAccountReceiveDate?: boolean;
    SumOfPrev?: boolean;
    IssueAtStart?: string;
    IssueAtEnd?: string;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<RemainingExtractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsRemainingExtractsGetPath, 'get');
    if (params) {
      rb.query('IsSearchByMissing', params.IsSearchByMissing, {});
      rb.query('FilterGovAgencies', params.FilterGovAgencies, {});
      rb.query('IssuedAt', params.IssuedAt, {});
      rb.query('AcquisitionDate', params.AcquisitionDate, {});
      rb.query('IsSelfFunded', params.IsSelfFunded, {});
      rb.query('Value', params.Value, {});
      rb.query('WorkDoneDate', params.WorkDoneDate, {});
      rb.query('WorkDoneValue', params.WorkDoneValue, {});
      rb.query('InitialValue', params.InitialValue, {});
      rb.query('ValueAfterCommissions', params.ValueAfterCommissions, {});
      rb.query('AccountingUnitReceivedAt', params.AccountingUnitReceivedAt, {});
      rb.query('BankAccountReceiveDate', params.BankAccountReceiveDate, {});
      rb.query('SumOfPrev', params.SumOfPrev, {});
      rb.query('IssueAtStart', params.IssueAtStart, {});
      rb.query('IssueAtEnd', params.IssueAtEnd, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RemainingExtractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsRemainingExtractsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsRemainingExtractsGet$Plain(params?: {
    IsSearchByMissing?: boolean;
    FilterGovAgencies?: Array<string>;
    IssuedAt?: boolean;
    AcquisitionDate?: boolean;
    IsSelfFunded?: boolean;
    Value?: boolean;
    WorkDoneDate?: boolean;
    WorkDoneValue?: boolean;
    InitialValue?: boolean;
    ValueAfterCommissions?: boolean;
    AccountingUnitReceivedAt?: boolean;
    BankAccountReceiveDate?: boolean;
    SumOfPrev?: boolean;
    IssueAtStart?: string;
    IssueAtEnd?: string;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<RemainingExtractVmPaginatedResponse> {

    return this.v10ApiProjectsRemainingExtractsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RemainingExtractVmPaginatedResponse>) => r.body as RemainingExtractVmPaginatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsRemainingExtractsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsRemainingExtractsGet$Json$Response(params?: {
    IsSearchByMissing?: boolean;
    FilterGovAgencies?: Array<string>;
    IssuedAt?: boolean;
    AcquisitionDate?: boolean;
    IsSelfFunded?: boolean;
    Value?: boolean;
    WorkDoneDate?: boolean;
    WorkDoneValue?: boolean;
    InitialValue?: boolean;
    ValueAfterCommissions?: boolean;
    AccountingUnitReceivedAt?: boolean;
    BankAccountReceiveDate?: boolean;
    SumOfPrev?: boolean;
    IssueAtStart?: string;
    IssueAtEnd?: string;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<RemainingExtractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsRemainingExtractsGetPath, 'get');
    if (params) {
      rb.query('IsSearchByMissing', params.IsSearchByMissing, {});
      rb.query('FilterGovAgencies', params.FilterGovAgencies, {});
      rb.query('IssuedAt', params.IssuedAt, {});
      rb.query('AcquisitionDate', params.AcquisitionDate, {});
      rb.query('IsSelfFunded', params.IsSelfFunded, {});
      rb.query('Value', params.Value, {});
      rb.query('WorkDoneDate', params.WorkDoneDate, {});
      rb.query('WorkDoneValue', params.WorkDoneValue, {});
      rb.query('InitialValue', params.InitialValue, {});
      rb.query('ValueAfterCommissions', params.ValueAfterCommissions, {});
      rb.query('AccountingUnitReceivedAt', params.AccountingUnitReceivedAt, {});
      rb.query('BankAccountReceiveDate', params.BankAccountReceiveDate, {});
      rb.query('SumOfPrev', params.SumOfPrev, {});
      rb.query('IssueAtStart', params.IssueAtStart, {});
      rb.query('IssueAtEnd', params.IssueAtEnd, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RemainingExtractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsRemainingExtractsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsRemainingExtractsGet$Json(params?: {
    IsSearchByMissing?: boolean;
    FilterGovAgencies?: Array<string>;
    IssuedAt?: boolean;
    AcquisitionDate?: boolean;
    IsSelfFunded?: boolean;
    Value?: boolean;
    WorkDoneDate?: boolean;
    WorkDoneValue?: boolean;
    InitialValue?: boolean;
    ValueAfterCommissions?: boolean;
    AccountingUnitReceivedAt?: boolean;
    BankAccountReceiveDate?: boolean;
    SumOfPrev?: boolean;
    IssueAtStart?: string;
    IssueAtEnd?: string;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<RemainingExtractVmPaginatedResponse> {

    return this.v10ApiProjectsRemainingExtractsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RemainingExtractVmPaginatedResponse>) => r.body as RemainingExtractVmPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsExtractIdGet
   */
  static readonly V10ApiProjectsIdExtractsExtractIdGetPath = '/v1.0/Api/Projects/{id}/Extracts/{extractId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsExtractIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdGet$Plain$Response(params: {
    id: string;
    extractId: string;
  }): Observable<StrictHttpResponse<ProjectExtractVm>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsExtractIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('extractId', params.extractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectExtractVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsExtractIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdGet$Plain(params: {
    id: string;
    extractId: string;
  }): Observable<ProjectExtractVm> {

    return this.v10ApiProjectsIdExtractsExtractIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectExtractVm>) => r.body as ProjectExtractVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsExtractIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdGet$Json$Response(params: {
    id: string;
    extractId: string;
  }): Observable<StrictHttpResponse<ProjectExtractVm>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsExtractIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('extractId', params.extractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectExtractVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsExtractIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdGet$Json(params: {
    id: string;
    extractId: string;
  }): Observable<ProjectExtractVm> {

    return this.v10ApiProjectsIdExtractsExtractIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectExtractVm>) => r.body as ProjectExtractVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsCreateExtractPost
   */
  static readonly V10ApiProjectsIdExtractsCreateExtractPostPath = '/v1.0/Api/Projects/{id}/Extracts/CreateExtract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsCreateExtractPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsCreateExtractPost$Plain$Response(params: {
    id: string;
    body?: ReqCreateExtractReqNewFilesWrapper
  }): Observable<StrictHttpResponse<StringDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsCreateExtractPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringDataWrapperVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsCreateExtractPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsCreateExtractPost$Plain(params: {
    id: string;
    body?: ReqCreateExtractReqNewFilesWrapper
  }): Observable<StringDataWrapperVm> {

    return this.v10ApiProjectsIdExtractsCreateExtractPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringDataWrapperVm>) => r.body as StringDataWrapperVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsCreateExtractPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsCreateExtractPost$Json$Response(params: {
    id: string;
    body?: ReqCreateExtractReqNewFilesWrapper
  }): Observable<StrictHttpResponse<StringDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsCreateExtractPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringDataWrapperVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsCreateExtractPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsCreateExtractPost$Json(params: {
    id: string;
    body?: ReqCreateExtractReqNewFilesWrapper
  }): Observable<StringDataWrapperVm> {

    return this.v10ApiProjectsIdExtractsCreateExtractPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringDataWrapperVm>) => r.body as StringDataWrapperVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsExtractIdReviewPut
   */
  static readonly V10ApiProjectsIdExtractsExtractIdReviewPutPath = '/v1.0/Api/Projects/{id}/Extracts/{extractId}/Review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsExtractIdReviewPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsExtractIdReviewPut$Response(params: {
    id: string;
    extractId: string;
    body?: ReqReviewExtractReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsExtractIdReviewPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('extractId', params.extractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsExtractIdReviewPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsExtractIdReviewPut(params: {
    id: string;
    extractId: string;
    body?: ReqReviewExtractReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiProjectsIdExtractsExtractIdReviewPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsExtractIdUpdatePut
   */
  static readonly V10ApiProjectsIdExtractsExtractIdUpdatePutPath = '/v1.0/Api/Projects/{id}/Extracts/{extractId}/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsExtractIdUpdatePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsExtractIdUpdatePut$Response(params: {
    id: string;
    extractId: string;
    body?: ReqCreateExtractReqOldNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsExtractIdUpdatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('extractId', params.extractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsExtractIdUpdatePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsIdExtractsExtractIdUpdatePut(params: {
    id: string;
    extractId: string;
    body?: ReqCreateExtractReqOldNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiProjectsIdExtractsExtractIdUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdExtractsExtractIdDeleteDelete
   */
  static readonly V10ApiProjectsIdExtractsExtractIdDeleteDeletePath = '/v1.0/Api/Projects/{id}/Extracts/{extractId}/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdExtractsExtractIdDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdDeleteDelete$Response(params: {
    id: string;
    extractId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExtractsService.V10ApiProjectsIdExtractsExtractIdDeleteDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('extractId', params.extractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdExtractsExtractIdDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdExtractsExtractIdDeleteDelete(params: {
    id: string;
    extractId: string;
  }): Observable<void> {

    return this.v10ApiProjectsIdExtractsExtractIdDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
