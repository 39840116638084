/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageViewModel } from '../models/message-view-model';
import { MessageViewModelPaginatedResponse } from '../models/message-view-model-paginated-response';
import { ProjectChatRoomAbstractVm } from '../models/project-chat-room-abstract-vm';
import { ProjectChatRoomVm } from '../models/project-chat-room-vm';
import { SignalRMessageVm } from '../models/signal-r-message-vm';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost
   */
  static readonly V10ApiChatRoomProjectIdRoomIdMessagesSendMessagePostPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}/Messages/SendMessage';

  /**
   * Send a message to a project,  not tested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Plain$Response(params: {
    projectId: string;
    roomId: string;
    body?: { 'Content'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<StrictHttpResponse<MessageViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdMessagesSendMessagePostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageViewModel>;
      })
    );
  }

  /**
   * Send a message to a project,  not tested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Plain(params: {
    projectId: string;
    roomId: string;
    body?: { 'Content'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<MessageViewModel> {

    return this.v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageViewModel>) => r.body as MessageViewModel)
    );
  }

  /**
   * Send a message to a project,  not tested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Json$Response(params: {
    projectId: string;
    roomId: string;
    body?: { 'Content'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<StrictHttpResponse<MessageViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdMessagesSendMessagePostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageViewModel>;
      })
    );
  }

  /**
   * Send a message to a project,  not tested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Json(params: {
    projectId: string;
    roomId: string;
    body?: { 'Content'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<MessageViewModel> {

    return this.v10ApiChatRoomProjectIdRoomIdMessagesSendMessagePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageViewModel>) => r.body as MessageViewModel)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdMessagesGet
   */
  static readonly V10ApiChatRoomProjectIdRoomIdMessagesGetPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}/Messages';

  /**
   * Get the messges of a project chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdMessagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesGet$Plain$Response(params: {
    projectId: string;
    roomId: string;
    pageIndex?: number;
    pageSize?: number;
    isPinned?: boolean;
  }): Observable<StrictHttpResponse<MessageViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdMessagesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('isPinned', params.isPinned, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get the messges of a project chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdMessagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesGet$Plain(params: {
    projectId: string;
    roomId: string;
    pageIndex?: number;
    pageSize?: number;
    isPinned?: boolean;
  }): Observable<MessageViewModelPaginatedResponse> {

    return this.v10ApiChatRoomProjectIdRoomIdMessagesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageViewModelPaginatedResponse>) => r.body as MessageViewModelPaginatedResponse)
    );
  }

  /**
   * Get the messges of a project chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdMessagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesGet$Json$Response(params: {
    projectId: string;
    roomId: string;
    pageIndex?: number;
    pageSize?: number;
    isPinned?: boolean;
  }): Observable<StrictHttpResponse<MessageViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdMessagesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('isPinned', params.isPinned, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get the messges of a project chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdMessagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesGet$Json(params: {
    projectId: string;
    roomId: string;
    pageIndex?: number;
    pageSize?: number;
    isPinned?: boolean;
  }): Observable<MessageViewModelPaginatedResponse> {

    return this.v10ApiChatRoomProjectIdRoomIdMessagesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageViewModelPaginatedResponse>) => r.body as MessageViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdGet
   */
  static readonly V10ApiChatRoomProjectIdRoomIdGetPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}';

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdGet$Plain$Response(params: {
    projectId: string;
    roomId: string;
  }): Observable<StrictHttpResponse<ProjectChatRoomVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectChatRoomVm>;
      })
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdGet$Plain(params: {
    projectId: string;
    roomId: string;
  }): Observable<ProjectChatRoomVm> {

    return this.v10ApiChatRoomProjectIdRoomIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectChatRoomVm>) => r.body as ProjectChatRoomVm)
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdGet$Json$Response(params: {
    projectId: string;
    roomId: string;
  }): Observable<StrictHttpResponse<ProjectChatRoomVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectChatRoomVm>;
      })
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdGet$Json(params: {
    projectId: string;
    roomId: string;
  }): Observable<ProjectChatRoomVm> {

    return this.v10ApiChatRoomProjectIdRoomIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectChatRoomVm>) => r.body as ProjectChatRoomVm)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdGet
   */
  static readonly V10ApiChatRoomProjectIdGetPath = '/v1.0/Api/ChatRoom/{projectId}';

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdGet$Plain$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>;
      })
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdGet$Plain(params: {
    projectId: string;
  }): Observable<Array<ProjectChatRoomAbstractVm>> {

    return this.v10ApiChatRoomProjectIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>) => r.body as Array<ProjectChatRoomAbstractVm>)
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdGet$Json$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>;
      })
    );
  }

  /**
   * Get Project Chat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdGet$Json(params: {
    projectId: string;
  }): Observable<Array<ProjectChatRoomAbstractVm>> {

    return this.v10ApiChatRoomProjectIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectChatRoomAbstractVm>>) => r.body as Array<ProjectChatRoomAbstractVm>)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut
   */
  static readonly V10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPutPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}/Messages/{messageId}/ChangePin';

  /**
   * Pin a message,  not tested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut$Response(params: {
    projectId: string;
    roomId: string;
    messageId: string;
    body?: { 'isPinned'?: boolean }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.path('messageId', params.messageId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Pin a message,  not tested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut(params: {
    projectId: string;
    roomId: string;
    messageId: string;
    body?: { 'isPinned'?: boolean }
  }): Observable<void> {

    return this.v10ApiChatRoomProjectIdRoomIdMessagesMessageIdChangePinPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdInviteParticipantPut
   */
  static readonly V10ApiChatRoomProjectIdRoomIdInviteParticipantPutPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}/InviteParticipant';

  /**
   * Invites a user to the group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdInviteParticipantPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdInviteParticipantPut$Response(params: {
    projectId: string;
    roomId: string;
    body?: { 'targetId'?: string, 'isAdmin'?: boolean }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdInviteParticipantPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Invites a user to the group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdInviteParticipantPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdRoomIdInviteParticipantPut(params: {
    projectId: string;
    roomId: string;
    body?: { 'targetId'?: string, 'isAdmin'?: boolean }
  }): Observable<void> {

    return this.v10ApiChatRoomProjectIdRoomIdInviteParticipantPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut
   */
  static readonly V10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPutPath = '/v1.0/Api/ChatRoom/{projectId}/{roomId}/KickParticipant/{targetId}';

  /**
   * Removes a user from the group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut$Response(params: {
    projectId: string;
    roomId: string;
    targetId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('roomId', params.roomId, {});
      rb.path('targetId', params.targetId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes a user from the group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut(params: {
    projectId: string;
    roomId: string;
    targetId: string;
  }): Observable<void> {

    return this.v10ApiChatRoomProjectIdRoomIdKickParticipantTargetIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut
   */
  static readonly V10ApiChatRoomProjectIdCreateOrUpdateChatRoomPutPath = '/v1.0/Api/ChatRoom/{projectId}/CreateOrUpdateChatRoom';

  /**
   * Creates or updates a chat room.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Plain$Response(params: {
    projectId: string;
    roomId?: string;
    body?: { 'RoomName'?: string, 'ImageFullUrl'?: string, 'IsPublic'?: boolean }
  }): Observable<StrictHttpResponse<ProjectChatRoomVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdCreateOrUpdateChatRoomPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('roomId', params.roomId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectChatRoomVm>;
      })
    );
  }

  /**
   * Creates or updates a chat room.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Plain(params: {
    projectId: string;
    roomId?: string;
    body?: { 'RoomName'?: string, 'ImageFullUrl'?: string, 'IsPublic'?: boolean }
  }): Observable<ProjectChatRoomVm> {

    return this.v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectChatRoomVm>) => r.body as ProjectChatRoomVm)
    );
  }

  /**
   * Creates or updates a chat room.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Json$Response(params: {
    projectId: string;
    roomId?: string;
    body?: { 'RoomName'?: string, 'ImageFullUrl'?: string, 'IsPublic'?: boolean }
  }): Observable<StrictHttpResponse<ProjectChatRoomVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiChatRoomProjectIdCreateOrUpdateChatRoomPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('roomId', params.roomId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectChatRoomVm>;
      })
    );
  }

  /**
   * Creates or updates a chat room.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Json(params: {
    projectId: string;
    roomId?: string;
    body?: { 'RoomName'?: string, 'ImageFullUrl'?: string, 'IsPublic'?: boolean }
  }): Observable<ProjectChatRoomVm> {

    return this.v10ApiChatRoomProjectIdCreateOrUpdateChatRoomPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectChatRoomVm>) => r.body as ProjectChatRoomVm)
    );
  }

  /**
   * Path part for operation v10ApiSignalRModelGet
   */
  static readonly V10ApiSignalRModelGetPath = '/v1.0/Api/SignalRModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiSignalRModelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiSignalRModelGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<SignalRMessageVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiSignalRModelGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignalRMessageVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiSignalRModelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiSignalRModelGet$Plain(params?: {
  }): Observable<SignalRMessageVm> {

    return this.v10ApiSignalRModelGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SignalRMessageVm>) => r.body as SignalRMessageVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiSignalRModelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiSignalRModelGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<SignalRMessageVm>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.V10ApiSignalRModelGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignalRMessageVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiSignalRModelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiSignalRModelGet$Json(params?: {
  }): Observable<SignalRMessageVm> {

    return this.v10ApiSignalRModelGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SignalRMessageVm>) => r.body as SignalRMessageVm)
    );
  }

}
