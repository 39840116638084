import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountsService } from '../api/services';
import { take, exhaustMap } from 'rxjs/operators';
import { ExtraAccountsService } from '../services/extra-account.service';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  constructor(private accountService: ExtraAccountsService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.accountService.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(request);
        }
        const modifiedReq = request.clone({
          headers: new HttpHeaders({
            Authorization: `Bearer ${user.accessToken}`,
          }),
        });

        return next.handle(modifiedReq);
      })
    );
  }
}

//  if (error.status === 0) {
//    this.router.navigate(['/noInternet']);
//  }
