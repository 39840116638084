/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReqCompleteTaskReqNewFilesWrapper } from '../models/req-complete-task-req-new-files-wrapper';
import { ReqTaskBaseReqNewFilesWrapper } from '../models/req-task-base-req-new-files-wrapper';
import { ReqTaskBaseReqOldNewFilesWrapper } from '../models/req-task-base-req-old-new-files-wrapper';
import { ReqTeamVmListReqNewFilesWrapper } from '../models/req-team-vm-list-req-new-files-wrapper';
import { StringListReqNewFilesWrapper } from '../models/string-list-req-new-files-wrapper';

@Injectable({
  providedIn: 'root',
})
export class CommitteeLeaderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPostPath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/UpdateTeams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost$Response(params: {
    projectId: string;
    committeeId: string;
    body?: ReqTeamVmListReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost(params: {
    projectId: string;
    committeeId: string;
    body?: ReqTeamVmListReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdUpdateTeamsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPostPath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/{teamId}/UpdateMembers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost$Response(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    body?: StringListReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    body?: StringListReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdUpdateMembersPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPostPath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/{teamId}/AddTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost$Response(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    body?: ReqTaskBaseReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    body?: ReqTaskBaseReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdAddTaskPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPutPath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/{teamId}/{taskId}/UpdateTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut$Response(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: ReqTaskBaseReqOldNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: ReqTaskBaseReqOldNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdUpdateTaskPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDeletePath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/{teamId}/{taskId}/DeleteTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete$Response(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDeletePath, 'delete');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdDeleteTaskDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut
   */
  static readonly V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPutPath = '/v1.0/Api/CommitteeLeader/{projectId}/{committeeId}/{teamId}/{taskId}/CompleteTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut$Response(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: ReqCompleteTaskReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommitteeLeaderService.V10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPutPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut(params: {
    projectId: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: ReqCompleteTaskReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiCommitteeLeaderProjectIdCommitteeIdTeamIdTaskIdCompleteTaskPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
