/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppFileViewModel } from '../models/app-file-view-model';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiFilesPost
   */
  static readonly V10ApiFilesPostPath = '/v1.0/Api/Files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFilesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFilesPost$Plain$Response(params?: {
    body?: { 'files'?: Array<Blob> }
  }): Observable<StrictHttpResponse<Array<AppFileViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.V10ApiFilesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppFileViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFilesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFilesPost$Plain(params?: {
    body?: { 'files'?: Array<Blob> }
  }): Observable<Array<AppFileViewModel>> {

    return this.v10ApiFilesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppFileViewModel>>) => r.body as Array<AppFileViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFilesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFilesPost$Json$Response(params?: {
    body?: { 'files'?: Array<Blob> }
  }): Observable<StrictHttpResponse<Array<AppFileViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.V10ApiFilesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppFileViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFilesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFilesPost$Json(params?: {
    body?: { 'files'?: Array<Blob> }
  }): Observable<Array<AppFileViewModel>> {

    return this.v10ApiFilesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppFileViewModel>>) => r.body as Array<AppFileViewModel>)
    );
  }

}
