import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExtraAccountsService } from '../services/extra-account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  tokenExpirationTimer!: any;

  constructor(
    private accountsService: ExtraAccountsService,
    private router: Router
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.accountsService.user.pipe(
      take(1),
      map(user => {
        const isAuth = !!user;
        if (isAuth) {
          return true;
        }
        return this.router.createUrlTree(['/authProEye']);
      })
    );
  }
}
