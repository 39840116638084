<div class="w-100 h-100" fxLayout="column" fxLayoutAlign="center center">
  <p class="font-custom page-404 text-shadow" fxLayoutAlign="center center">
    <span>4</span>
    <img
      style="margin-bottom: 3rem"
      class="logo-image"
      src="../../assets/images/logo-01.png"
      alt=""
    />
    <span>4</span>
  </p>
  <p class="font-custom text-bold-9 page-not-found">page not found</p>
  <button
    mat-raised-button
    style="
      background-color: #0a3556;
      color: #fff;
      width: 30rem;
      margin-top: 3rem;
    "
    class="btn-rounded"
    (click)="onBack()"
  >
    العودة
  </button>
</div>
