/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountsService } from './services/accounts.service';
import { ChatService } from './services/chat.service';
import { CitiesService } from './services/cities.service';
import { CommitteeLeaderService } from './services/committee-leader.service';
import { ProjectService } from './services/project.service';
import { CompaniesService } from './services/companies.service';
import { DeveloperService } from './services/developer.service';
import { EngManagementDirectorService } from './services/eng-management-director.service';
import { ExecutiveManagerService } from './services/executive-manager.service';
import { ExtractsService } from './services/extracts.service';
import { FcmService } from './services/fcm.service';
import { FilesService } from './services/files.service';
import { PublicPageService } from './services/public-page.service';
import { StatsService } from './services/stats.service';
import { TechnicalReportsService } from './services/technical-reports.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountsService,
    ChatService,
    CitiesService,
    CommitteeLeaderService,
    ProjectService,
    CompaniesService,
    DeveloperService,
    EngManagementDirectorService,
    ExecutiveManagerService,
    ExtractsService,
    FcmService,
    FilesService,
    PublicPageService,
    StatsService,
    TechnicalReportsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
