/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectNameId } from '../models/project-name-id';
import { ReqAcceptProjectReqNewFilesWrapper } from '../models/req-accept-project-req-new-files-wrapper';
import { ReqCreateCommitteeReqNewFilesWrapper } from '../models/req-create-committee-req-new-files-wrapper';
import { ReqUpdateCommitteeReqNewFilesWrapper } from '../models/req-update-committee-req-new-files-wrapper';
import { ReqUpdateProjectInfoReqOldNewFilesWrapper } from '../models/req-update-project-info-req-old-new-files-wrapper';
import { RespProjectRequest } from '../models/resp-project-request';

@Injectable({
  providedIn: 'root',
})
export class ExecutiveManagerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsRequestsGet
   */
  static readonly V10ApiExecutionManagerProjectsRequestsGetPath = '/v1.0/Api/ExecutionManager/Projects/Requests';

  /**
   * Get project requests for Executive manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProjectNameId>>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectNameId>>;
      })
    );
  }

  /**
   * Get project requests for Executive manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsGet$Plain(params?: {
  }): Observable<Array<ProjectNameId>> {

    return this.v10ApiExecutionManagerProjectsRequestsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectNameId>>) => r.body as Array<ProjectNameId>)
    );
  }

  /**
   * Get project requests for Executive manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProjectNameId>>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectNameId>>;
      })
    );
  }

  /**
   * Get project requests for Executive manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsGet$Json(params?: {
  }): Observable<Array<ProjectNameId>> {

    return this.v10ApiExecutionManagerProjectsRequestsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectNameId>>) => r.body as Array<ProjectNameId>)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsRequestsIdGet
   */
  static readonly V10ApiExecutionManagerProjectsRequestsIdGetPath = '/v1.0/Api/ExecutionManager/Projects/Requests/{id}';

  /**
   * Get single project request for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsIdGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RespProjectRequest>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RespProjectRequest>;
      })
    );
  }

  /**
   * Get single project request for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsIdGet$Plain(params: {
    id: string;
  }): Observable<RespProjectRequest> {

    return this.v10ApiExecutionManagerProjectsRequestsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RespProjectRequest>) => r.body as RespProjectRequest)
    );
  }

  /**
   * Get single project request for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsIdGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RespProjectRequest>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RespProjectRequest>;
      })
    );
  }

  /**
   * Get single project request for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsRequestsIdGet$Json(params: {
    id: string;
  }): Observable<RespProjectRequest> {

    return this.v10ApiExecutionManagerProjectsRequestsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RespProjectRequest>) => r.body as RespProjectRequest)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost
   */
  static readonly V10ApiExecutionManagerProjectsRequestsIdRejectRequestPostPath = '/v1.0/Api/ExecutionManager/Projects/Requests/{id}/RejectRequest';

  /**
   * Reject Project Request for Executive manager .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost$Response(params: {
    id: string;
    body?: { 'RefuseReason'?: string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsIdRejectRequestPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reject Project Request for Executive manager .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost(params: {
    id: string;
    body?: { 'RefuseReason'?: string }
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsRequestsIdRejectRequestPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsIdUpdateProjectPost
   */
  static readonly V10ApiExecutionManagerProjectsIdUpdateProjectPostPath = '/v1.0/Api/ExecutionManager/Projects/{id}/UpdateProject';

  /**
   * Update project for executive manager, call this to edit project request, or edit an accepted project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsIdUpdateProjectPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdUpdateProjectPost$Response(params: {
    id: string;
    body?: ReqUpdateProjectInfoReqOldNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsIdUpdateProjectPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update project for executive manager, call this to edit project request, or edit an accepted project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsIdUpdateProjectPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdUpdateProjectPost(params: {
    id: string;
    body?: ReqUpdateProjectInfoReqOldNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsIdUpdateProjectPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost
   */
  static readonly V10ApiExecutionManagerProjectsRequestsIdAcceptRequestPostPath = '/v1.0/Api/ExecutionManager/Projects/Requests/{id}/AcceptRequest';

  /**
   * Complete a project requests for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost$Response(params: {
    id: string;
    body?: ReqAcceptProjectReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsRequestsIdAcceptRequestPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Complete a project requests for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost(params: {
    id: string;
    body?: ReqAcceptProjectReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsRequestsIdAcceptRequestPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsIdAddCommitteePost
   */
  static readonly V10ApiExecutionManagerProjectsIdAddCommitteePostPath = '/v1.0/Api/ExecutionManager/Projects/{id}/AddCommittee';

  /**
   * Creates a committee for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsIdAddCommitteePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdAddCommitteePost$Response(params: {
    id: string;
    body?: ReqCreateCommitteeReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsIdAddCommitteePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates a committee for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsIdAddCommitteePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdAddCommitteePost(params: {
    id: string;
    body?: ReqCreateCommitteeReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsIdAddCommitteePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut
   */
  static readonly V10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPutPath = '/v1.0/Api/ExecutionManager/Projects/{id}/UpdateCommittee/{committeeId}';

  /**
   * Updates a committee for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut$Response(params: {
    id: string;
    committeeId: string;
    body?: ReqUpdateCommitteeReqNewFilesWrapper
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a committee for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut(params: {
    id: string;
    committeeId: string;
    body?: ReqUpdateCommitteeReqNewFilesWrapper
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsIdUpdateCommitteeCommitteeIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete
   */
  static readonly V10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDeletePath = '/v1.0/Api/ExecutionManager/Projects/{id}/DeleteCommittee/{committeeId}';

  /**
   * Delets a committee for Execution manager.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete$Response(params: {
    id: string;
    committeeId: string;
    NewFilesToAdd?: Array<string>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExecutiveManagerService.V10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
      rb.query('NewFilesToAdd', params.NewFilesToAdd, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delets a committee for Execution manager.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete(params: {
    id: string;
    committeeId: string;
    NewFilesToAdd?: Array<string>;
  }): Observable<void> {

    return this.v10ApiExecutionManagerProjectsIdDeleteCommitteeCommitteeIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
