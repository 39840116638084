/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FcmService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiFcmAssignTokenPost
   */
  static readonly V10ApiFcmAssignTokenPostPath = '/v1.0/Api/FCM/AssignToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFcmAssignTokenPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFcmAssignTokenPost$Response(params?: {
    body?: { 'token'?: string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FcmService.V10ApiFcmAssignTokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFcmAssignTokenPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFcmAssignTokenPost(params?: {
    body?: { 'token'?: string }
  }): Observable<void> {

    return this.v10ApiFcmAssignTokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiFcmRemoveTokenDelete
   */
  static readonly V10ApiFcmRemoveTokenDeletePath = '/v1.0/Api/FCM/RemoveToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFcmRemoveTokenDelete()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFcmRemoveTokenDelete$Response(params?: {
    body?: { 'token'?: string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FcmService.V10ApiFcmRemoveTokenDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFcmRemoveTokenDelete$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiFcmRemoveTokenDelete(params?: {
    body?: { 'token'?: string }
  }): Observable<void> {

    return this.v10ApiFcmRemoveTokenDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiFcmRemoveAllTokensDelete
   */
  static readonly V10ApiFcmRemoveAllTokensDeletePath = '/v1.0/Api/FCM/RemoveAllTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFcmRemoveAllTokensDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiFcmRemoveAllTokensDelete$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FcmService.V10ApiFcmRemoveAllTokensDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFcmRemoveAllTokensDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiFcmRemoveAllTokensDelete(params?: {
  }): Observable<void> {

    return this.v10ApiFcmRemoveAllTokensDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiFcmListTokensGet
   */
  static readonly V10ApiFcmListTokensGetPath = '/v1.0/Api/FCM/ListTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiFcmListTokensGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiFcmListTokensGet$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FcmService.V10ApiFcmListTokensGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiFcmListTokensGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiFcmListTokensGet(params?: {
  }): Observable<void> {

    return this.v10ApiFcmListTokensGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
