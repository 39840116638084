/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DateBasedOn } from '../models/date-based-on';
import { DatedExtractsStatsObject } from '../models/dated-extracts-stats-object';
import { DatedProjectStatsObject } from '../models/dated-project-stats-object';
import { DetailedDatedExtractStats } from '../models/detailed-dated-extract-stats';
import { ExtractStatsDateType } from '../models/extract-stats-date-type';
import { StatisticsProjectState } from '../models/statistics-project-state';
import { StatsObject } from '../models/stats-object';
import { StringSimpleProjectInfoWithCommsVm } from '../models/string-simple-project-info-with-comms-vm';

@Injectable({
  providedIn: 'root',
})
export class StatsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiGetProjectsStatsGet
   */
  static readonly V10ApiGetProjectsStatsGetPath = '/v1.0/Api/GetProjectsStats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetProjectsStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetProjectsStatsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<StatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetProjectsStatsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetProjectsStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetProjectsStatsGet$Plain(params?: {
  }): Observable<StatsObject> {

    return this.v10ApiGetProjectsStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StatsObject>) => r.body as StatsObject)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetProjectsStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetProjectsStatsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<StatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetProjectsStatsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetProjectsStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetProjectsStatsGet$Json(params?: {
  }): Observable<StatsObject> {

    return this.v10ApiGetProjectsStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StatsObject>) => r.body as StatsObject)
    );
  }

  /**
   * Path part for operation v10ApiGetDatedExtractsStatsGet
   */
  static readonly V10ApiGetDatedExtractsStatsGetPath = '/v1.0/Api/GetDatedExtractsStats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDatedExtractsStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedExtractsStatsGet$Plain$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
  }): Observable<StrictHttpResponse<DatedExtractsStatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDatedExtractsStatsGetPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('DateType', params.DateType, {});
      rb.query('ProjectState', params.ProjectState, {});
      rb.query('IncludeNullDates', params.IncludeNullDates, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatedExtractsStatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDatedExtractsStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedExtractsStatsGet$Plain(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
  }): Observable<DatedExtractsStatsObject> {

    return this.v10ApiGetDatedExtractsStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DatedExtractsStatsObject>) => r.body as DatedExtractsStatsObject)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDatedExtractsStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedExtractsStatsGet$Json$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
  }): Observable<StrictHttpResponse<DatedExtractsStatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDatedExtractsStatsGetPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('DateType', params.DateType, {});
      rb.query('ProjectState', params.ProjectState, {});
      rb.query('IncludeNullDates', params.IncludeNullDates, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatedExtractsStatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDatedExtractsStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedExtractsStatsGet$Json(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
  }): Observable<DatedExtractsStatsObject> {

    return this.v10ApiGetDatedExtractsStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DatedExtractsStatsObject>) => r.body as DatedExtractsStatsObject)
    );
  }

  /**
   * Path part for operation v10ApiGetDetailedDatedExtractStatsGet
   */
  static readonly V10ApiGetDetailedDatedExtractStatsGetPath = '/v1.0/Api/GetDetailedDatedExtractStats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDetailedDatedExtractStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedExtractStatsGet$Plain$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
    isInMansUni?: boolean;
    isSelf?: boolean;
    sectionId?: number;
  }): Observable<StrictHttpResponse<DetailedDatedExtractStats>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDetailedDatedExtractStatsGetPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('DateType', params.DateType, {});
      rb.query('ProjectState', params.ProjectState, {});
      rb.query('IncludeNullDates', params.IncludeNullDates, {});
      rb.query('isInMansUni', params.isInMansUni, {});
      rb.query('isSelf', params.isSelf, {});
      rb.query('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedDatedExtractStats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDetailedDatedExtractStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedExtractStatsGet$Plain(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
    isInMansUni?: boolean;
    isSelf?: boolean;
    sectionId?: number;
  }): Observable<DetailedDatedExtractStats> {

    return this.v10ApiGetDetailedDatedExtractStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedDatedExtractStats>) => r.body as DetailedDatedExtractStats)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDetailedDatedExtractStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedExtractStatsGet$Json$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
    isInMansUni?: boolean;
    isSelf?: boolean;
    sectionId?: number;
  }): Observable<StrictHttpResponse<DetailedDatedExtractStats>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDetailedDatedExtractStatsGetPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('DateType', params.DateType, {});
      rb.query('ProjectState', params.ProjectState, {});
      rb.query('IncludeNullDates', params.IncludeNullDates, {});
      rb.query('isInMansUni', params.isInMansUni, {});
      rb.query('isSelf', params.isSelf, {});
      rb.query('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedDatedExtractStats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDetailedDatedExtractStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedExtractStatsGet$Json(params?: {
    StartDate?: string;
    EndDate?: string;
    DateType?: ExtractStatsDateType;
    ProjectState?: StatisticsProjectState;
    IncludeNullDates?: boolean;
    isInMansUni?: boolean;
    isSelf?: boolean;
    sectionId?: number;
  }): Observable<DetailedDatedExtractStats> {

    return this.v10ApiGetDetailedDatedExtractStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedDatedExtractStats>) => r.body as DetailedDatedExtractStats)
    );
  }

  /**
   * Path part for operation v10ApiGetDatedProjectStatsGet
   */
  static readonly V10ApiGetDatedProjectStatsGetPath = '/v1.0/Api/GetDatedProjectStats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDatedProjectStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedProjectStatsGet$Plain$Response(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
  }): Observable<StrictHttpResponse<DatedProjectStatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDatedProjectStatsGetPath, 'get');
    if (params) {
      rb.query('dateBasedOn', params.dateBasedOn, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('projectState', params.projectState, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatedProjectStatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDatedProjectStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedProjectStatsGet$Plain(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
  }): Observable<DatedProjectStatsObject> {

    return this.v10ApiGetDatedProjectStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DatedProjectStatsObject>) => r.body as DatedProjectStatsObject)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDatedProjectStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedProjectStatsGet$Json$Response(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
  }): Observable<StrictHttpResponse<DatedProjectStatsObject>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDatedProjectStatsGetPath, 'get');
    if (params) {
      rb.query('dateBasedOn', params.dateBasedOn, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('projectState', params.projectState, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatedProjectStatsObject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDatedProjectStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDatedProjectStatsGet$Json(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
  }): Observable<DatedProjectStatsObject> {

    return this.v10ApiGetDatedProjectStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DatedProjectStatsObject>) => r.body as DatedProjectStatsObject)
    );
  }

  /**
   * Path part for operation v10ApiGetDetailedDatedProjectStatsGet
   */
  static readonly V10ApiGetDetailedDatedProjectStatsGetPath = '/v1.0/Api/GetDetailedDatedProjectStats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDetailedDatedProjectStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedProjectStatsGet$Plain$Response(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
    isInMansUni?: boolean;
    sectionId?: number;
  }): Observable<StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDetailedDatedProjectStatsGetPath, 'get');
    if (params) {
      rb.query('dateBasedOn', params.dateBasedOn, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('projectState', params.projectState, {});
      rb.query('isInMansUni', params.isInMansUni, {});
      rb.query('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDetailedDatedProjectStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedProjectStatsGet$Plain(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
    isInMansUni?: boolean;
    sectionId?: number;
  }): Observable<Array<StringSimpleProjectInfoWithCommsVm>> {

    return this.v10ApiGetDetailedDatedProjectStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>) => r.body as Array<StringSimpleProjectInfoWithCommsVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGetDetailedDatedProjectStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedProjectStatsGet$Json$Response(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
    isInMansUni?: boolean;
    sectionId?: number;
  }): Observable<StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.V10ApiGetDetailedDatedProjectStatsGetPath, 'get');
    if (params) {
      rb.query('dateBasedOn', params.dateBasedOn, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('projectState', params.projectState, {});
      rb.query('isInMansUni', params.isInMansUni, {});
      rb.query('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGetDetailedDatedProjectStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGetDetailedDatedProjectStatsGet$Json(params?: {
    dateBasedOn?: DateBasedOn;
    startDate?: string;
    endDate?: string;
    projectState?: StatisticsProjectState;
    isInMansUni?: boolean;
    sectionId?: number;
  }): Observable<Array<StringSimpleProjectInfoWithCommsVm>> {

    return this.v10ApiGetDetailedDatedProjectStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringSimpleProjectInfoWithCommsVm>>) => r.body as Array<StringSimpleProjectInfoWithCommsVm>)
    );
  }

}
