/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompleteProjectDetailsVm } from '../models/complete-project-details-vm';
import { ProjectAbstractViewModelPaginatedResponse } from '../models/project-abstract-view-model-paginated-response';
import { ProjectCommitteeVm } from '../models/project-committee-vm';
import { ProjectTaskVm } from '../models/project-task-vm';
import { ReqCreateProjectReqNewFilesWrapper } from '../models/req-create-project-req-new-files-wrapper';
import { StringDataWrapperVm } from '../models/string-data-wrapper-vm';
import { TransactionViewModelPaginatedResponse } from '../models/transaction-view-model-paginated-response';
import { WarrantyStageFilterEnum } from '../models/warranty-stage-filter-enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost
   */
  static readonly V10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPostPath = '/v1.0/Api/Projects/{id}/{committeeId}/{teamId}/{taskId}/AddComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost$Response(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: { 'comment'?: string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
    body?: { 'comment'?: string }
  }): Observable<void> {

    return this.v10ApiProjectsIdCommitteeIdTeamIdTaskIdAddCommentPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiProjectsGet
   */
  static readonly V10ApiProjectsGetPath = '/v1.0/Api/Projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsGet$Plain$Response(params?: {
    filterStr?: string;
    isDone?: boolean;
    pageIndex?: number;
    pageSize?: number;
    deniedOnly?: boolean;
    warrantyStage?: WarrantyStageFilterEnum;
  }): Observable<StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsGetPath, 'get');
    if (params) {
      rb.query('filterStr', params.filterStr, {});
      rb.query('isDone', params.isDone, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('deniedOnly', params.deniedOnly, {});
      rb.query('warrantyStage', params.warrantyStage, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsGet$Plain(params?: {
    filterStr?: string;
    isDone?: boolean;
    pageIndex?: number;
    pageSize?: number;
    deniedOnly?: boolean;
    warrantyStage?: WarrantyStageFilterEnum;
  }): Observable<ProjectAbstractViewModelPaginatedResponse> {

    return this.v10ApiProjectsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>) => r.body as ProjectAbstractViewModelPaginatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsGet$Json$Response(params?: {
    filterStr?: string;
    isDone?: boolean;
    pageIndex?: number;
    pageSize?: number;
    deniedOnly?: boolean;
    warrantyStage?: WarrantyStageFilterEnum;
  }): Observable<StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsGetPath, 'get');
    if (params) {
      rb.query('filterStr', params.filterStr, {});
      rb.query('isDone', params.isDone, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('deniedOnly', params.deniedOnly, {});
      rb.query('warrantyStage', params.warrantyStage, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsGet$Json(params?: {
    filterStr?: string;
    isDone?: boolean;
    pageIndex?: number;
    pageSize?: number;
    deniedOnly?: boolean;
    warrantyStage?: WarrantyStageFilterEnum;
  }): Observable<ProjectAbstractViewModelPaginatedResponse> {

    return this.v10ApiProjectsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectAbstractViewModelPaginatedResponse>) => r.body as ProjectAbstractViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdGet
   */
  static readonly V10ApiProjectsIdGetPath = '/v1.0/Api/Projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompleteProjectDetailsVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteProjectDetailsVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdGet$Plain(params: {
    id: string;
  }): Observable<CompleteProjectDetailsVm> {

    return this.v10ApiProjectsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteProjectDetailsVm>) => r.body as CompleteProjectDetailsVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompleteProjectDetailsVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteProjectDetailsVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdGet$Json(params: {
    id: string;
  }): Observable<CompleteProjectDetailsVm> {

    return this.v10ApiProjectsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteProjectDetailsVm>) => r.body as CompleteProjectDetailsVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdCommitteeCommitteeIdGet
   */
  static readonly V10ApiProjectsIdCommitteeCommitteeIdGetPath = '/v1.0/Api/Projects/{id}/Committee/{committeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdCommitteeCommitteeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdGet$Plain$Response(params: {
    id: string;
    committeeId: string;
  }): Observable<StrictHttpResponse<ProjectCommitteeVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdCommitteeCommitteeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectCommitteeVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdCommitteeCommitteeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdGet$Plain(params: {
    id: string;
    committeeId: string;
  }): Observable<ProjectCommitteeVm> {

    return this.v10ApiProjectsIdCommitteeCommitteeIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectCommitteeVm>) => r.body as ProjectCommitteeVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdCommitteeCommitteeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdGet$Json$Response(params: {
    id: string;
    committeeId: string;
  }): Observable<StrictHttpResponse<ProjectCommitteeVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdCommitteeCommitteeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectCommitteeVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdCommitteeCommitteeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdGet$Json(params: {
    id: string;
    committeeId: string;
  }): Observable<ProjectCommitteeVm> {

    return this.v10ApiProjectsIdCommitteeCommitteeIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectCommitteeVm>) => r.body as ProjectCommitteeVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet
   */
  static readonly V10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGetPath = '/v1.0/Api/Projects/{id}/Committee/{committeeId}/{teamId}/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Plain$Response(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<StrictHttpResponse<ProjectTaskVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectTaskVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Plain(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<ProjectTaskVm> {

    return this.v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectTaskVm>) => r.body as ProjectTaskVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Json$Response(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<StrictHttpResponse<ProjectTaskVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('committeeId', params.committeeId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectTaskVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Json(params: {
    id: string;
    committeeId: string;
    teamId: string;
    taskId: string;
  }): Observable<ProjectTaskVm> {

    return this.v10ApiProjectsIdCommitteeCommitteeIdTeamIdTaskIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectTaskVm>) => r.body as ProjectTaskVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsTransactionsGet
   */
  static readonly V10ApiProjectsTransactionsGetPath = '/v1.0/Api/Projects/Transactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsTransactionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsTransactionsGet$Plain$Response(params?: {
    ByIssuer?: string;
    ByReviewer?: string;
    ByProject?: string;
    After?: string;
    Before?: string;
    PageIndex?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<TransactionViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsTransactionsGetPath, 'get');
    if (params) {
      rb.query('ByIssuer', params.ByIssuer, {});
      rb.query('ByReviewer', params.ByReviewer, {});
      rb.query('ByProject', params.ByProject, {});
      rb.query('After', params.After, {});
      rb.query('Before', params.Before, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsTransactionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsTransactionsGet$Plain(params?: {
    ByIssuer?: string;
    ByReviewer?: string;
    ByProject?: string;
    After?: string;
    Before?: string;
    PageIndex?: number;
    PageSize?: number;
  }): Observable<TransactionViewModelPaginatedResponse> {

    return this.v10ApiProjectsTransactionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionViewModelPaginatedResponse>) => r.body as TransactionViewModelPaginatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsTransactionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsTransactionsGet$Json$Response(params?: {
    ByIssuer?: string;
    ByReviewer?: string;
    ByProject?: string;
    After?: string;
    Before?: string;
    PageIndex?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<TransactionViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsTransactionsGetPath, 'get');
    if (params) {
      rb.query('ByIssuer', params.ByIssuer, {});
      rb.query('ByReviewer', params.ByReviewer, {});
      rb.query('ByProject', params.ByProject, {});
      rb.query('After', params.After, {});
      rb.query('Before', params.Before, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsTransactionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsTransactionsGet$Json(params?: {
    ByIssuer?: string;
    ByReviewer?: string;
    ByProject?: string;
    After?: string;
    Before?: string;
    PageIndex?: number;
    PageSize?: number;
  }): Observable<TransactionViewModelPaginatedResponse> {

    return this.v10ApiProjectsTransactionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionViewModelPaginatedResponse>) => r.body as TransactionViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiProjectsV1Post
   */
  static readonly V10ApiProjectsV1PostPath = '/v1.0/Api/Projects/V1';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsV1Post$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsV1Post$Plain$Response(params?: {
    body?: ReqCreateProjectReqNewFilesWrapper
  }): Observable<StrictHttpResponse<StringDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsV1PostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringDataWrapperVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsV1Post$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsV1Post$Plain(params?: {
    body?: ReqCreateProjectReqNewFilesWrapper
  }): Observable<StringDataWrapperVm> {

    return this.v10ApiProjectsV1Post$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringDataWrapperVm>) => r.body as StringDataWrapperVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsV1Post$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsV1Post$Json$Response(params?: {
    body?: ReqCreateProjectReqNewFilesWrapper
  }): Observable<StrictHttpResponse<StringDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsV1PostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringDataWrapperVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsV1Post$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiProjectsV1Post$Json(params?: {
    body?: ReqCreateProjectReqNewFilesWrapper
  }): Observable<StringDataWrapperVm> {

    return this.v10ApiProjectsV1Post$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringDataWrapperVm>) => r.body as StringDataWrapperVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsDeleteAllDelete
   */
  static readonly V10ApiProjectsDeleteAllDeletePath = '/v1.0/Api/Projects/DeleteAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsDeleteAllDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsDeleteAllDelete$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.V10ApiProjectsDeleteAllDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsDeleteAllDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsDeleteAllDelete(params?: {
  }): Observable<void> {

    return this.v10ApiProjectsDeleteAllDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
