import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-no-page',
  templateUrl: './no-page.component.html',
  styleUrls: ['./no-page.component.scss'],
})
export class NoPageComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit(): void {}

  onBack(): void {
    this.location.back();
  }
}
