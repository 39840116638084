/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbstractGeneralActivityVm } from '../models/abstract-general-activity-vm';
import { AbstractGeneralSectionVm } from '../models/abstract-general-section-vm';
import { CenterMainWorkVm } from '../models/center-main-work-vm';
import { ContactUsVm } from '../models/contact-us-vm';
import { DetailedGeneralActivityVm } from '../models/detailed-general-activity-vm';
import { DetailedGeneralSectionVm } from '../models/detailed-general-section-vm';
import { GeneralPostVm } from '../models/general-post-vm';
import { IntroductionVm } from '../models/introduction-vm';
import { ManagerVm } from '../models/manager-vm';

@Injectable({
  providedIn: 'root',
})
export class PublicPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesCreateOrUpdateActivityPost
   */
  static readonly V10ApiPublicPageActivitiesCreateOrUpdateActivityPostPath = '/v1.0/Api/PublicPage/Activities/CreateOrUpdateActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Plain$Response(params?: {
    activityId?: string;
    body?: { 'Title'?: string, 'FullImageUrl'?: string }
  }): Observable<StrictHttpResponse<AbstractGeneralActivityVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesCreateOrUpdateActivityPostPath, 'post');
    if (params) {
      rb.query('activityId', params.activityId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AbstractGeneralActivityVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Plain(params?: {
    activityId?: string;
    body?: { 'Title'?: string, 'FullImageUrl'?: string }
  }): Observable<AbstractGeneralActivityVm> {

    return this.v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AbstractGeneralActivityVm>) => r.body as AbstractGeneralActivityVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Json$Response(params?: {
    activityId?: string;
    body?: { 'Title'?: string, 'FullImageUrl'?: string }
  }): Observable<StrictHttpResponse<AbstractGeneralActivityVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesCreateOrUpdateActivityPostPath, 'post');
    if (params) {
      rb.query('activityId', params.activityId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AbstractGeneralActivityVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Json(params?: {
    activityId?: string;
    body?: { 'Title'?: string, 'FullImageUrl'?: string }
  }): Observable<AbstractGeneralActivityVm> {

    return this.v10ApiPublicPageActivitiesCreateOrUpdateActivityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AbstractGeneralActivityVm>) => r.body as AbstractGeneralActivityVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost
   */
  static readonly V10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPostPath = '/v1.0/Api/PublicPage/Activities/{activityId}/{sectionId}/CreateOrUpdatePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Plain$Response(params: {
    activityId: string;
    sectionId: string;
    postId?: string;
    body?: { 'Title'?: string, 'Desc'?: string, 'FullImageUrls'?: Array<string> }
  }): Observable<StrictHttpResponse<GeneralPostVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPostPath, 'post');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.path('sectionId', params.sectionId, {});
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralPostVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Plain(params: {
    activityId: string;
    sectionId: string;
    postId?: string;
    body?: { 'Title'?: string, 'Desc'?: string, 'FullImageUrls'?: Array<string> }
  }): Observable<GeneralPostVm> {

    return this.v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralPostVm>) => r.body as GeneralPostVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Json$Response(params: {
    activityId: string;
    sectionId: string;
    postId?: string;
    body?: { 'Title'?: string, 'Desc'?: string, 'FullImageUrls'?: Array<string> }
  }): Observable<StrictHttpResponse<GeneralPostVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPostPath, 'post');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.path('sectionId', params.sectionId, {});
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralPostVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Json(params: {
    activityId: string;
    sectionId: string;
    postId?: string;
    body?: { 'Title'?: string, 'Desc'?: string, 'FullImageUrls'?: Array<string> }
  }): Observable<GeneralPostVm> {

    return this.v10ApiPublicPageActivitiesActivityIdSectionIdCreateOrUpdatePostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralPostVm>) => r.body as GeneralPostVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost
   */
  static readonly V10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPostPath = '/v1.0/Api/PublicPage/Activities/{activityId}/CreateOrUpdateSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Plain$Response(params: {
    activityId: string;
    sectionId?: string;
    body?: { 'title'?: string }
  }): Observable<StrictHttpResponse<AbstractGeneralSectionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPostPath, 'post');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.query('sectionId', params.sectionId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AbstractGeneralSectionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Plain(params: {
    activityId: string;
    sectionId?: string;
    body?: { 'title'?: string }
  }): Observable<AbstractGeneralSectionVm> {

    return this.v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AbstractGeneralSectionVm>) => r.body as AbstractGeneralSectionVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Json$Response(params: {
    activityId: string;
    sectionId?: string;
    body?: { 'title'?: string }
  }): Observable<StrictHttpResponse<AbstractGeneralSectionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPostPath, 'post');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.query('sectionId', params.sectionId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AbstractGeneralSectionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Json(params: {
    activityId: string;
    sectionId?: string;
    body?: { 'title'?: string }
  }): Observable<AbstractGeneralSectionVm> {

    return this.v10ApiPublicPageActivitiesActivityIdCreateOrUpdateSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AbstractGeneralSectionVm>) => r.body as AbstractGeneralSectionVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete
   */
  static readonly V10ApiPublicPageActivitiesActivityIdDeleteActivityDeletePath = '/v1.0/Api/PublicPage/Activities/{activityId}/DeleteActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete$Response(params: {
    activityId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdDeleteActivityDeletePath, 'delete');
    if (params) {
      rb.path('activityId', params.activityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete(params: {
    activityId: string;
  }): Observable<void> {

    return this.v10ApiPublicPageActivitiesActivityIdDeleteActivityDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPagePostIdDeletePostDelete
   */
  static readonly V10ApiPublicPagePostIdDeletePostDeletePath = '/v1.0/Api/PublicPage/{postId}/DeletePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPagePostIdDeletePostDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPagePostIdDeletePostDelete$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPagePostIdDeletePostDeletePath, 'delete');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPagePostIdDeletePostDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPagePostIdDeletePostDelete(params: {
    postId: string;
  }): Observable<void> {

    return this.v10ApiPublicPagePostIdDeletePostDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageSectionIdDeleteSectionDelete
   */
  static readonly V10ApiPublicPageSectionIdDeleteSectionDeletePath = '/v1.0/Api/PublicPage/{sectionId}/DeleteSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageSectionIdDeleteSectionDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageSectionIdDeleteSectionDelete$Response(params: {
    sectionId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageSectionIdDeleteSectionDeletePath, 'delete');
    if (params) {
      rb.path('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageSectionIdDeleteSectionDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageSectionIdDeleteSectionDelete(params: {
    sectionId: string;
  }): Observable<void> {

    return this.v10ApiPublicPageSectionIdDeleteSectionDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesGet
   */
  static readonly V10ApiPublicPageActivitiesGetPath = '/v1.0/Api/PublicPage/Activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AbstractGeneralActivityVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AbstractGeneralActivityVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesGet$Plain(params?: {
  }): Observable<Array<AbstractGeneralActivityVm>> {

    return this.v10ApiPublicPageActivitiesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AbstractGeneralActivityVm>>) => r.body as Array<AbstractGeneralActivityVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AbstractGeneralActivityVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AbstractGeneralActivityVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesGet$Json(params?: {
  }): Observable<Array<AbstractGeneralActivityVm>> {

    return this.v10ApiPublicPageActivitiesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AbstractGeneralActivityVm>>) => r.body as Array<AbstractGeneralActivityVm>)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesActivityIdGet
   */
  static readonly V10ApiPublicPageActivitiesActivityIdGetPath = '/v1.0/Api/PublicPage/Activities/{activityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdGet$Plain$Response(params: {
    activityId: string;
  }): Observable<StrictHttpResponse<DetailedGeneralActivityVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdGetPath, 'get');
    if (params) {
      rb.path('activityId', params.activityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedGeneralActivityVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdGet$Plain(params: {
    activityId: string;
  }): Observable<DetailedGeneralActivityVm> {

    return this.v10ApiPublicPageActivitiesActivityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedGeneralActivityVm>) => r.body as DetailedGeneralActivityVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdGet$Json$Response(params: {
    activityId: string;
  }): Observable<StrictHttpResponse<DetailedGeneralActivityVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdGetPath, 'get');
    if (params) {
      rb.path('activityId', params.activityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedGeneralActivityVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdGet$Json(params: {
    activityId: string;
  }): Observable<DetailedGeneralActivityVm> {

    return this.v10ApiPublicPageActivitiesActivityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedGeneralActivityVm>) => r.body as DetailedGeneralActivityVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageActivitiesActivityIdSectionIdGet
   */
  static readonly V10ApiPublicPageActivitiesActivityIdSectionIdGetPath = '/v1.0/Api/PublicPage/Activities/{activityId}/{sectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdSectionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdGet$Plain$Response(params: {
    activityId: string;
    sectionId: string;
  }): Observable<StrictHttpResponse<DetailedGeneralSectionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdSectionIdGetPath, 'get');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.path('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedGeneralSectionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdSectionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdGet$Plain(params: {
    activityId: string;
    sectionId: string;
  }): Observable<DetailedGeneralSectionVm> {

    return this.v10ApiPublicPageActivitiesActivityIdSectionIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedGeneralSectionVm>) => r.body as DetailedGeneralSectionVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageActivitiesActivityIdSectionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdGet$Json$Response(params: {
    activityId: string;
    sectionId: string;
  }): Observable<StrictHttpResponse<DetailedGeneralSectionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageActivitiesActivityIdSectionIdGetPath, 'get');
    if (params) {
      rb.path('activityId', params.activityId, {});
      rb.path('sectionId', params.sectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DetailedGeneralSectionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageActivitiesActivityIdSectionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageActivitiesActivityIdSectionIdGet$Json(params: {
    activityId: string;
    sectionId: string;
  }): Observable<DetailedGeneralSectionVm> {

    return this.v10ApiPublicPageActivitiesActivityIdSectionIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DetailedGeneralSectionVm>) => r.body as DetailedGeneralSectionVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageGetMainWorksGet
   */
  static readonly V10ApiPublicPageGetMainWorksGetPath = '/v1.0/Api/PublicPage/GetMainWorks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetMainWorksGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetMainWorksGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CenterMainWorkVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetMainWorksGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CenterMainWorkVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetMainWorksGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetMainWorksGet$Plain(params?: {
  }): Observable<Array<CenterMainWorkVm>> {

    return this.v10ApiPublicPageGetMainWorksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CenterMainWorkVm>>) => r.body as Array<CenterMainWorkVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetMainWorksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetMainWorksGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CenterMainWorkVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetMainWorksGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CenterMainWorkVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetMainWorksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetMainWorksGet$Json(params?: {
  }): Observable<Array<CenterMainWorkVm>> {

    return this.v10ApiPublicPageGetMainWorksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CenterMainWorkVm>>) => r.body as Array<CenterMainWorkVm>)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageSetMainWorksPut
   */
  static readonly V10ApiPublicPageSetMainWorksPutPath = '/v1.0/Api/PublicPage/SetMainWorks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageSetMainWorksPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetMainWorksPut$Response(params?: {
    body?: Array<CenterMainWorkVm>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageSetMainWorksPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageSetMainWorksPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetMainWorksPut(params?: {
    body?: Array<CenterMainWorkVm>
  }): Observable<void> {

    return this.v10ApiPublicPageSetMainWorksPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageGetIntroductionGet
   */
  static readonly V10ApiPublicPageGetIntroductionGetPath = '/v1.0/Api/PublicPage/GetIntroduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetIntroductionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetIntroductionGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<IntroductionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetIntroductionGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntroductionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetIntroductionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetIntroductionGet$Plain(params?: {
  }): Observable<IntroductionVm> {

    return this.v10ApiPublicPageGetIntroductionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<IntroductionVm>) => r.body as IntroductionVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetIntroductionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetIntroductionGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<IntroductionVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetIntroductionGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntroductionVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetIntroductionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetIntroductionGet$Json(params?: {
  }): Observable<IntroductionVm> {

    return this.v10ApiPublicPageGetIntroductionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<IntroductionVm>) => r.body as IntroductionVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageSetIntroductionPut
   */
  static readonly V10ApiPublicPageSetIntroductionPutPath = '/v1.0/Api/PublicPage/SetIntroduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageSetIntroductionPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetIntroductionPut$Response(params?: {
    body?: IntroductionVm
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageSetIntroductionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageSetIntroductionPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetIntroductionPut(params?: {
    body?: IntroductionVm
  }): Observable<void> {

    return this.v10ApiPublicPageSetIntroductionPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageGetContactUsGet
   */
  static readonly V10ApiPublicPageGetContactUsGetPath = '/v1.0/Api/PublicPage/GetContactUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetContactUsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetContactUsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ContactUsVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetContactUsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactUsVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetContactUsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetContactUsGet$Plain(params?: {
  }): Observable<ContactUsVm> {

    return this.v10ApiPublicPageGetContactUsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactUsVm>) => r.body as ContactUsVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetContactUsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetContactUsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ContactUsVm>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetContactUsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactUsVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetContactUsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetContactUsGet$Json(params?: {
  }): Observable<ContactUsVm> {

    return this.v10ApiPublicPageGetContactUsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactUsVm>) => r.body as ContactUsVm)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageSetContactUsPut
   */
  static readonly V10ApiPublicPageSetContactUsPutPath = '/v1.0/Api/PublicPage/SetContactUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageSetContactUsPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetContactUsPut$Response(params?: {
    body?: ContactUsVm
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageSetContactUsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageSetContactUsPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetContactUsPut(params?: {
    body?: ContactUsVm
  }): Observable<void> {

    return this.v10ApiPublicPageSetContactUsPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageGetManagersGet
   */
  static readonly V10ApiPublicPageGetManagersGetPath = '/v1.0/Api/PublicPage/GetManagers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetManagersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetManagersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ManagerVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetManagersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManagerVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetManagersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetManagersGet$Plain(params?: {
  }): Observable<Array<ManagerVm>> {

    return this.v10ApiPublicPageGetManagersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManagerVm>>) => r.body as Array<ManagerVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageGetManagersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetManagersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ManagerVm>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageGetManagersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManagerVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageGetManagersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiPublicPageGetManagersGet$Json(params?: {
  }): Observable<Array<ManagerVm>> {

    return this.v10ApiPublicPageGetManagersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManagerVm>>) => r.body as Array<ManagerVm>)
    );
  }

  /**
   * Path part for operation v10ApiPublicPageSetManagersPut
   */
  static readonly V10ApiPublicPageSetManagersPutPath = '/v1.0/Api/PublicPage/SetManagers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiPublicPageSetManagersPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetManagersPut$Response(params?: {
    body?: Array<ManagerVm>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicPageService.V10ApiPublicPageSetManagersPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiPublicPageSetManagersPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v10ApiPublicPageSetManagersPut(params?: {
    body?: Array<ManagerVm>
  }): Observable<void> {

    return this.v10ApiPublicPageSetManagersPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
