/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TechnicalReportAbstractVmPaginatedResponse } from '../models/technical-report-abstract-vm-paginated-response';
import { TechnicalReportVm } from '../models/technical-report-vm';

@Injectable({
  providedIn: 'root',
})
export class TechnicalReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiProjectsIdTechnicalReportsGet
   */
  static readonly V10ApiProjectsIdTechnicalReportsGetPath = '/v1.0/Api/Projects/{id}/TechnicalReports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsGet$Plain$Response(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
  }): Observable<StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterStr', params.filterStr, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsGet$Plain(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
  }): Observable<TechnicalReportAbstractVmPaginatedResponse> {

    return this.v10ApiProjectsIdTechnicalReportsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>) => r.body as TechnicalReportAbstractVmPaginatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsGet$Json$Response(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
  }): Observable<StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterStr', params.filterStr, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsGet$Json(params: {
    id: string;
    pageIndex?: number;
    pageSize?: number;
    filterStr?: string;
  }): Observable<TechnicalReportAbstractVmPaginatedResponse> {

    return this.v10ApiProjectsIdTechnicalReportsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportAbstractVmPaginatedResponse>) => r.body as TechnicalReportAbstractVmPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdTechnicalReportsReportIdGet
   */
  static readonly V10ApiProjectsIdTechnicalReportsReportIdGetPath = '/v1.0/Api/Projects/{id}/TechnicalReports/{reportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsReportIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsReportIdGet$Plain$Response(params: {
    id: string;
    reportId: string;
  }): Observable<StrictHttpResponse<TechnicalReportVm>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsReportIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('reportId', params.reportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsReportIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsReportIdGet$Plain(params: {
    id: string;
    reportId: string;
  }): Observable<TechnicalReportVm> {

    return this.v10ApiProjectsIdTechnicalReportsReportIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportVm>) => r.body as TechnicalReportVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsReportIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsReportIdGet$Json$Response(params: {
    id: string;
    reportId: string;
  }): Observable<StrictHttpResponse<TechnicalReportVm>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsReportIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('reportId', params.reportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsReportIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiProjectsIdTechnicalReportsReportIdGet$Json(params: {
    id: string;
    reportId: string;
  }): Observable<TechnicalReportVm> {

    return this.v10ApiProjectsIdTechnicalReportsReportIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportVm>) => r.body as TechnicalReportVm)
    );
  }

  /**
   * Path part for operation v10ApiProjectsIdTechnicalReportsCreateReportPost
   */
  static readonly V10ApiProjectsIdTechnicalReportsCreateReportPostPath = '/v1.0/Api/Projects/{id}/TechnicalReports/CreateReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsCreateReportPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdTechnicalReportsCreateReportPost$Plain$Response(params: {
    id: string;
    body?: { 'Desc'?: string, 'Title'?: string, 'Date'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<StrictHttpResponse<TechnicalReportVm>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsCreateReportPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsCreateReportPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdTechnicalReportsCreateReportPost$Plain(params: {
    id: string;
    body?: { 'Desc'?: string, 'Title'?: string, 'Date'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<TechnicalReportVm> {

    return this.v10ApiProjectsIdTechnicalReportsCreateReportPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportVm>) => r.body as TechnicalReportVm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiProjectsIdTechnicalReportsCreateReportPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdTechnicalReportsCreateReportPost$Json$Response(params: {
    id: string;
    body?: { 'Desc'?: string, 'Title'?: string, 'Date'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<StrictHttpResponse<TechnicalReportVm>> {

    const rb = new RequestBuilder(this.rootUrl, TechnicalReportsService.V10ApiProjectsIdTechnicalReportsCreateReportPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechnicalReportVm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiProjectsIdTechnicalReportsCreateReportPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiProjectsIdTechnicalReportsCreateReportPost$Json(params: {
    id: string;
    body?: { 'Desc'?: string, 'Title'?: string, 'Date'?: string, 'OfficialRequestFiles'?: Array<Blob> }
  }): Observable<TechnicalReportVm> {

    return this.v10ApiProjectsIdTechnicalReportsCreateReportPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TechnicalReportVm>) => r.body as TechnicalReportVm)
    );
  }

}
