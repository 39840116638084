/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanySection } from '../models/company-section';
import { ExecutionCompanyViewModel } from '../models/execution-company-view-model';
import { ExecutionCompanyViewModelDataWrapperVm } from '../models/execution-company-view-model-data-wrapper-vm';
import { ExecutionCompanyViewModelPaginatedResponse } from '../models/execution-company-view-model-paginated-response';
import { GovCompanyViewModel } from '../models/gov-company-view-model';
import { GovCompanyViewModelDataWrapperVm } from '../models/gov-company-view-model-data-wrapper-vm';
import { GovCompanyViewModelPaginatedResponse } from '../models/gov-company-view-model-paginated-response';
import { Int32DataWrapperVm } from '../models/int-32-data-wrapper-vm';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiCompaniesExecutionGet
   */
  static readonly V10ApiCompaniesExecutionGetPath = '/v1.0/Api/Companies/Execution';

  /**
   * Get Execution Companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionGet$Plain$Response(params?: {
    textFilter?: string;
    isVerified?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get Execution Companies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionGet$Plain(params?: {
    textFilter?: string;
    isVerified?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<ExecutionCompanyViewModelPaginatedResponse> {

    return this.v10ApiCompaniesExecutionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>) => r.body as ExecutionCompanyViewModelPaginatedResponse)
    );
  }

  /**
   * Get Execution Companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionGet$Json$Response(params?: {
    textFilter?: string;
    isVerified?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get Execution Companies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionGet$Json(params?: {
    textFilter?: string;
    isVerified?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<ExecutionCompanyViewModelPaginatedResponse> {

    return this.v10ApiCompaniesExecutionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModelPaginatedResponse>) => r.body as ExecutionCompanyViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesExecutionPost
   */
  static readonly V10ApiCompaniesExecutionPostPath = '/v1.0/Api/Companies/Execution';

  /**
   * Create Execution Company, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesExecutionPost$Plain$Response(params?: {
    id?: string;
    body?: { 'EngLayer'?: string, 'CompanyRegister'?: string, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>;
      })
    );
  }

  /**
   * Create Execution Company, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesExecutionPost$Plain(params?: {
    id?: string;
    body?: { 'EngLayer'?: string, 'CompanyRegister'?: string, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<ExecutionCompanyViewModelDataWrapperVm> {

    return this.v10ApiCompaniesExecutionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>) => r.body as ExecutionCompanyViewModelDataWrapperVm)
    );
  }

  /**
   * Create Execution Company, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesExecutionPost$Json$Response(params?: {
    id?: string;
    body?: { 'EngLayer'?: string, 'CompanyRegister'?: string, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>;
      })
    );
  }

  /**
   * Create Execution Company, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesExecutionPost$Json(params?: {
    id?: string;
    body?: { 'EngLayer'?: string, 'CompanyRegister'?: string, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<ExecutionCompanyViewModelDataWrapperVm> {

    return this.v10ApiCompaniesExecutionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModelDataWrapperVm>) => r.body as ExecutionCompanyViewModelDataWrapperVm)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesGovsGet
   */
  static readonly V10ApiCompaniesGovsGetPath = '/v1.0/Api/Companies/Govs';

  /**
   * Get Gov Companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsGet$Plain$Response(params?: {
    textFilter?: string;
    sectionId?: number;
    isVerified?: boolean;
    isInMansUniversity?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<GovCompanyViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovsGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('sectionId', params.sectionId, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('isInMansUniversity', params.isInMansUniversity, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get Gov Companies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsGet$Plain(params?: {
    textFilter?: string;
    sectionId?: number;
    isVerified?: boolean;
    isInMansUniversity?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<GovCompanyViewModelPaginatedResponse> {

    return this.v10ApiCompaniesGovsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModelPaginatedResponse>) => r.body as GovCompanyViewModelPaginatedResponse)
    );
  }

  /**
   * Get Gov Companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsGet$Json$Response(params?: {
    textFilter?: string;
    sectionId?: number;
    isVerified?: boolean;
    isInMansUniversity?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<GovCompanyViewModelPaginatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovsGetPath, 'get');
    if (params) {
      rb.query('textFilter', params.textFilter, {});
      rb.query('sectionId', params.sectionId, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('isInMansUniversity', params.isInMansUniversity, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModelPaginatedResponse>;
      })
    );
  }

  /**
   * Get Gov Companies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsGet$Json(params?: {
    textFilter?: string;
    sectionId?: number;
    isVerified?: boolean;
    isInMansUniversity?: boolean;
    pageIndex?: number;
    pageSize?: number;
  }): Observable<GovCompanyViewModelPaginatedResponse> {

    return this.v10ApiCompaniesGovsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModelPaginatedResponse>) => r.body as GovCompanyViewModelPaginatedResponse)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesGovPost
   */
  static readonly V10ApiCompaniesGovPostPath = '/v1.0/Api/Companies/Gov';

  /**
   * Create Government Company , Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesGovPost$Plain$Response(params?: {
    id?: string;
    body?: { 'SectionId': number, 'IsInMansouraUniversity'?: boolean, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<StrictHttpResponse<GovCompanyViewModelDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModelDataWrapperVm>;
      })
    );
  }

  /**
   * Create Government Company , Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesGovPost$Plain(params?: {
    id?: string;
    body?: { 'SectionId': number, 'IsInMansouraUniversity'?: boolean, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<GovCompanyViewModelDataWrapperVm> {

    return this.v10ApiCompaniesGovPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModelDataWrapperVm>) => r.body as GovCompanyViewModelDataWrapperVm)
    );
  }

  /**
   * Create Government Company , Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesGovPost$Json$Response(params?: {
    id?: string;
    body?: { 'SectionId': number, 'IsInMansouraUniversity'?: boolean, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<StrictHttpResponse<GovCompanyViewModelDataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModelDataWrapperVm>;
      })
    );
  }

  /**
   * Create Government Company , Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiCompaniesGovPost$Json(params?: {
    id?: string;
    body?: { 'SectionId': number, 'IsInMansouraUniversity'?: boolean, 'Name': string, 'Phone1'?: string, 'Phone2'?: string, 'Address'?: string, 'Fax'?: string, 'Notes'?: string, 'Email'?: string }
  }): Observable<GovCompanyViewModelDataWrapperVm> {

    return this.v10ApiCompaniesGovPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModelDataWrapperVm>) => r.body as GovCompanyViewModelDataWrapperVm)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesGovsCompanyIdGet
   */
  static readonly V10ApiCompaniesGovsCompanyIdGetPath = '/v1.0/Api/Companies/Govs/{companyId}';

  /**
   * Get Single Gov Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovsCompanyIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsCompanyIdGet$Plain$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<GovCompanyViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovsCompanyIdGetPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModel>;
      })
    );
  }

  /**
   * Get Single Gov Company.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovsCompanyIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsCompanyIdGet$Plain(params: {
    companyId: string;
  }): Observable<GovCompanyViewModel> {

    return this.v10ApiCompaniesGovsCompanyIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModel>) => r.body as GovCompanyViewModel)
    );
  }

  /**
   * Get Single Gov Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesGovsCompanyIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsCompanyIdGet$Json$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<GovCompanyViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesGovsCompanyIdGetPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GovCompanyViewModel>;
      })
    );
  }

  /**
   * Get Single Gov Company.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesGovsCompanyIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesGovsCompanyIdGet$Json(params: {
    companyId: string;
  }): Observable<GovCompanyViewModel> {

    return this.v10ApiCompaniesGovsCompanyIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GovCompanyViewModel>) => r.body as GovCompanyViewModel)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesExecutionsCompanyIdGet
   */
  static readonly V10ApiCompaniesExecutionsCompanyIdGetPath = '/v1.0/Api/Companies/Executions/{companyId}';

  /**
   * Get Single Execution Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionsCompanyIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionsCompanyIdGet$Plain$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionsCompanyIdGetPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModel>;
      })
    );
  }

  /**
   * Get Single Execution Company.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionsCompanyIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionsCompanyIdGet$Plain(params: {
    companyId: string;
  }): Observable<ExecutionCompanyViewModel> {

    return this.v10ApiCompaniesExecutionsCompanyIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModel>) => r.body as ExecutionCompanyViewModel)
    );
  }

  /**
   * Get Single Execution Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesExecutionsCompanyIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionsCompanyIdGet$Json$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<ExecutionCompanyViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesExecutionsCompanyIdGetPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecutionCompanyViewModel>;
      })
    );
  }

  /**
   * Get Single Execution Company.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesExecutionsCompanyIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesExecutionsCompanyIdGet$Json(params: {
    companyId: string;
  }): Observable<ExecutionCompanyViewModel> {

    return this.v10ApiCompaniesExecutionsCompanyIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExecutionCompanyViewModel>) => r.body as ExecutionCompanyViewModel)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesIdDelete
   */
  static readonly V10ApiCompaniesIdDeletePath = '/v1.0/Api/Companies/{id}';

  /**
   * Delete a Company , System Admin Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a Company , System Admin Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiCompaniesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesIdDeactivatePut
   */
  static readonly V10ApiCompaniesIdDeactivatePutPath = '/v1.0/Api/Companies/{id}/Deactivate';

  /**
   * Deactivates a company , System Admin Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesIdDeactivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdDeactivatePut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesIdDeactivatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivates a company , System Admin Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesIdDeactivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdDeactivatePut(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiCompaniesIdDeactivatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiCompaniesIdActivatePut
   */
  static readonly V10ApiCompaniesIdActivatePutPath = '/v1.0/Api/Companies/{id}/Activate';

  /**
   * Activates a Company , System Admin Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiCompaniesIdActivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdActivatePut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiCompaniesIdActivatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activates a Company , System Admin Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiCompaniesIdActivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiCompaniesIdActivatePut(params: {
    id: string;
  }): Observable<void> {

    return this.v10ApiCompaniesIdActivatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v10ApiImportGovCompaniesPut
   */
  static readonly V10ApiImportGovCompaniesPutPath = '/v1.0/Api/ImportGovCompanies';

  /**
   * Import gov companies , System Admin Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiImportGovCompaniesPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiImportGovCompaniesPut$Response(params?: {
    body?: { 'InternalCSV'?: Blob, 'ExternalCSV'?: Blob }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.V10ApiImportGovCompaniesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Import gov companies , System Admin Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiImportGovCompaniesPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v10ApiImportGovCompaniesPut(params?: {
    body?: { 'InternalCSV'?: Blob, 'ExternalCSV'?: Blob }
  }): Observable<void> {

    return this.v10ApiImportGovCompaniesPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sectionsGet
   */
  static readonly SectionsGetPath = '/Sections';

  /**
   * Get sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsGet$Plain$Response(params?: {
    partialName?: string;
    isActive?: boolean;
  }): Observable<StrictHttpResponse<Array<CompanySection>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsGetPath, 'get');
    if (params) {
      rb.query('partialName', params.partialName, {});
      rb.query('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanySection>>;
      })
    );
  }

  /**
   * Get sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsGet$Plain(params?: {
    partialName?: string;
    isActive?: boolean;
  }): Observable<Array<CompanySection>> {

    return this.sectionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanySection>>) => r.body as Array<CompanySection>)
    );
  }

  /**
   * Get sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsGet$Json$Response(params?: {
    partialName?: string;
    isActive?: boolean;
  }): Observable<StrictHttpResponse<Array<CompanySection>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsGetPath, 'get');
    if (params) {
      rb.query('partialName', params.partialName, {});
      rb.query('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanySection>>;
      })
    );
  }

  /**
   * Get sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsGet$Json(params?: {
    partialName?: string;
    isActive?: boolean;
  }): Observable<Array<CompanySection>> {

    return this.sectionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanySection>>) => r.body as Array<CompanySection>)
    );
  }

  /**
   * Path part for operation sectionsPost
   */
  static readonly SectionsPostPath = '/Sections';

  /**
   * Create a section,,  Require System Admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsPost$Plain$Response(params?: {
    body?: { 'name'?: string }
  }): Observable<StrictHttpResponse<Int32DataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32DataWrapperVm>;
      })
    );
  }

  /**
   * Create a section,,  Require System Admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsPost$Plain(params?: {
    body?: { 'name'?: string }
  }): Observable<Int32DataWrapperVm> {

    return this.sectionsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32DataWrapperVm>) => r.body as Int32DataWrapperVm)
    );
  }

  /**
   * Create a section,,  Require System Admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsPost$Json$Response(params?: {
    body?: { 'name'?: string }
  }): Observable<StrictHttpResponse<Int32DataWrapperVm>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32DataWrapperVm>;
      })
    );
  }

  /**
   * Create a section,,  Require System Admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsPost$Json(params?: {
    body?: { 'name'?: string }
  }): Observable<Int32DataWrapperVm> {

    return this.sectionsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32DataWrapperVm>) => r.body as Int32DataWrapperVm)
    );
  }

  /**
   * Path part for operation sectionsIdPut
   */
  static readonly SectionsIdPutPath = '/Sections/{id}';

  /**
   * Update Section ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsIdPut$Response(params: {
    id: number;
    body?: { 'name'?: string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Section ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sectionsIdPut(params: {
    id: number;
    body?: { 'name'?: string }
  }): Observable<void> {

    return this.sectionsIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sectionsIdDelete
   */
  static readonly SectionsIdDeletePath = '/Sections/{id}';

  /**
   * Delete Sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Sections ,, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.sectionsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sectionsIdActivatePut
   */
  static readonly SectionsIdActivatePutPath = '/Sections/{id}/Activate';

  /**
   * Activate a deleted section,, Sys Admin Require.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sectionsIdActivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsIdActivatePut$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.SectionsIdActivatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate a deleted section,, Sys Admin Require.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sectionsIdActivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sectionsIdActivatePut(params: {
    id: number;
  }): Observable<void> {

    return this.sectionsIdActivatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
