/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GovsCitiesViewModelCity } from '../models/govs-cities-view-model-city';
import { GovsCitiesViewModelGov } from '../models/govs-cities-view-model-gov';

@Injectable({
  providedIn: 'root',
})
export class CitiesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v10ApiGovsIdCitiesGet
   */
  static readonly V10ApiGovsIdCitiesGetPath = '/v1.0/Api/Govs/{id}/Cities';

  /**
   * Get Cities, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGovsIdCitiesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsIdCitiesGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<GovsCitiesViewModelCity>>> {

    const rb = new RequestBuilder(this.rootUrl, CitiesService.V10ApiGovsIdCitiesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GovsCitiesViewModelCity>>;
      })
    );
  }

  /**
   * Get Cities, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGovsIdCitiesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsIdCitiesGet$Plain(params: {
    id: number;
  }): Observable<Array<GovsCitiesViewModelCity>> {

    return this.v10ApiGovsIdCitiesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GovsCitiesViewModelCity>>) => r.body as Array<GovsCitiesViewModelCity>)
    );
  }

  /**
   * Get Cities, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGovsIdCitiesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsIdCitiesGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<GovsCitiesViewModelCity>>> {

    const rb = new RequestBuilder(this.rootUrl, CitiesService.V10ApiGovsIdCitiesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GovsCitiesViewModelCity>>;
      })
    );
  }

  /**
   * Get Cities, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGovsIdCitiesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsIdCitiesGet$Json(params: {
    id: number;
  }): Observable<Array<GovsCitiesViewModelCity>> {

    return this.v10ApiGovsIdCitiesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GovsCitiesViewModelCity>>) => r.body as Array<GovsCitiesViewModelCity>)
    );
  }

  /**
   * Path part for operation v10ApiGovsGet
   */
  static readonly V10ApiGovsGetPath = '/v1.0/Api/Govs';

  /**
   * Get Governorates, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGovsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsGet$Plain$Response(params?: {
    searchParam?: string;
  }): Observable<StrictHttpResponse<Array<GovsCitiesViewModelGov>>> {

    const rb = new RequestBuilder(this.rootUrl, CitiesService.V10ApiGovsGetPath, 'get');
    if (params) {
      rb.query('searchParam', params.searchParam, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GovsCitiesViewModelGov>>;
      })
    );
  }

  /**
   * Get Governorates, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGovsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsGet$Plain(params?: {
    searchParam?: string;
  }): Observable<Array<GovsCitiesViewModelGov>> {

    return this.v10ApiGovsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GovsCitiesViewModelGov>>) => r.body as Array<GovsCitiesViewModelGov>)
    );
  }

  /**
   * Get Governorates, Anonymous Usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGovsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsGet$Json$Response(params?: {
    searchParam?: string;
  }): Observable<StrictHttpResponse<Array<GovsCitiesViewModelGov>>> {

    const rb = new RequestBuilder(this.rootUrl, CitiesService.V10ApiGovsGetPath, 'get');
    if (params) {
      rb.query('searchParam', params.searchParam, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GovsCitiesViewModelGov>>;
      })
    );
  }

  /**
   * Get Governorates, Anonymous Usage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGovsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsGet$Json(params?: {
    searchParam?: string;
  }): Observable<Array<GovsCitiesViewModelGov>> {

    return this.v10ApiGovsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GovsCitiesViewModelGov>>) => r.body as Array<GovsCitiesViewModelGov>)
    );
  }

  /**
   * Path part for operation v10ApiGovsSeedCitiesFromLocalCsvPut
   */
  static readonly V10ApiGovsSeedCitiesFromLocalCsvPutPath = '/v1.0/Api/Govs/SeedCitiesFromLocalCsv';

  /**
   * Seed Cities From Local Csv ,, system admin Required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v10ApiGovsSeedCitiesFromLocalCsvPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsSeedCitiesFromLocalCsvPut$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CitiesService.V10ApiGovsSeedCitiesFromLocalCsvPutPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Seed Cities From Local Csv ,, system admin Required.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v10ApiGovsSeedCitiesFromLocalCsvPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v10ApiGovsSeedCitiesFromLocalCsvPut(params?: {
  }): Observable<void> {

    return this.v10ApiGovsSeedCitiesFromLocalCsvPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
